import React from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import './App.css';
import Navbar from './Components/Navbar2/Navbar1';
import HomePage from './Pages/Home';
import SignUp from './Pages/SignUp';
import Footer from './Components/Footer';
import Login from './Pages/Login';
import CreateHome from './Pages/Dashboard/dashboard';
import CreateQR from './Pages/NewQR';
import Designing from './Pages/Designing';
import Redirect from './Pages/redirect';
import Success from './Pages/Payment.js/Success';
import QrCodeGenerator from './Pages/QrPackage';
import PrivacyAndPolicy from './Pages/Docoments/privacyAndPolicy';
import TermsOfUse from './Pages/Docoments/termOfUse';
import { useEffect } from 'react';
// import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
  

// const TRACKING_ID = "G-C7D8KWQJ8Q";
  // ReactGA.initialize(TRACKING_ID);

function App() {

  
  
  useEffect(() => {
    ReactGA.initialize('G-5E3R92ZEF5'); // Replace with your Measurement ID
    ReactGA.send("pageview");
  }, []);

  return (
    <>
      
      <Router>
      {/* <Analytics /> */}
      <Routes>
   <Route path="/" Component={HomePage} />
        <Route path="/:appName/:appId" Component={Redirect} />
        <Route path="/success" Component={Success} />
        <Route path="/signup" Component={SignUp}/>
        <Route path="login" Component={Login}/>
        <Route path="/home" Component={CreateHome}/>
        <Route path="/new-qr" Component={CreateQR}/>
        <Route path="/designs/:qrId" Component={Designing}/>
        <Route path="/test" Component={QrCodeGenerator}/>
        <Route path="/privacy" Component={PrivacyAndPolicy}/>
        <Route path="/termOfUse" Component={TermsOfUse}/>
        {/* <Route path="/test" Component={Test}/> */}
        
      </Routes>
      </Router>
     
    </>
  );
}

export default App;
