import { useEffect, useState } from "react";
import HomePage from "./Home";
import { useNavigate, useParams } from "react-router-dom";
import { url } from "../urlConfig";


function Redirect() {
const [theData,setTheData]=useState({})

const { appName } = useParams();
console.log(appName)
const navigate =useNavigate()
    function getOS() {
        const userAgent = window.navigator.userAgent;
        const platform = window.navigator?.userAgentData?.platform || window.navigator.platform;
        const macosPlatforms = ['macOS', 'Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
        const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
        const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
    
        if (macosPlatforms.includes(platform)) {
          return 'Mac OS';
        } else if (iosPlatforms.includes(platform)) {
          return 'iOS';
        } else if (windowsPlatforms.includes(platform)) 
          {
          return 'Windows';
        } else if (/Android/.test(userAgent)) {
          return 'Android';
        } else if (/Linux/.test(platform)) {
          return 'Linux';
        }
        return null;
      }
    
      useEffect(() => {
 
        const os = getOS();
        // const theData = data.find((item) => item.appId === id);
        fetch(`${url}/qr-by-name/${appName}`,{
            headers: {  "Content-Type": "application/json" },
          }).then((res)=>{return res.json()})
          .then(response=>{
            if(response.status===false){
              let userId=response.data.userId
              if(response.data.linkUrl===""){
             
                fetch(`${url}/user/${userId}`,{
                  headers: {  "Content-Type": "application/json" },
                }).then((res)=>{return res.json()}).then(response2=>{
                  if(response2.data.planId===""){
                    if (os === 'iOS') {
                   alert("IOS device detected. Please continue with payment for activation.")
                    } else if (os === 'Android') {
                     alert("Android device detected. Please continue with payment for activation.")
                    } else {
                      console.log(theData);
                     alert("Please scan in mobile")
                     navigate("/")
                    }
                  }else{
                    setTheData(response.data)
                    if (os === 'iOS') {
                       window.location.href = response.data.appStoreUrl;
                     } else if (os === 'Android') {
                       window.location.href = response.data.playStoreUrl;
                     } else {
                       console.log(theData);
                      alert("Please scan in mobile")
                      navigate("/")
                     }
                  }
                })
              }else{
             
              fetch(`${url}/user/${userId}`,{
                headers: {  "Content-Type": "application/json" },
              }).then((res)=>{return res.json()}).then(response2=>{
                if(response2.data.planId===""){
                  if (os === 'iOS') {
                 alert("IOS device detected. Please continue with payment for activation.")
                 navigate("/")
                  } else if (os === 'Android') {
                   alert("Android device detected. Please continue with payment for activation.")
                   navigate("/")
                  } else {
                    console.log(theData);
                   alert("Please scan in mobile")
                   navigate("/")
                  }
                }else{
                  setTheData(response.data)
                  // if (os === 'iOS') {
                     window.location.href = response.data.linkUrl;
                  //  } else if (os === 'Android') {
                    //  window.location.href = response.data.playStoreUrl;
                  // //  } else {
                  //    console.log(theData);
                  //   alert("Please scan in mobile")
                  //  }
                }
              })
            }
            }else{
                
            
            }
          })
       
      }, [appName]);
    return ( 
        <>
        <HomePage/>
        </>
     );
}

export default Redirect;