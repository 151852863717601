import { Link, NavLink } from "react-router-dom";
import React, { useEffect, useState } from "react";
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import { url } from "../../urlConfig";
import QrCode from "../QrCode";
import { Divider, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
function Home() {
    const useId =localStorage.getItem("userId")
    const [user,setUser]=useState({})
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [copyMessage, setCopyMessage] = useState("");
    useEffect(()=>{
        if(useId){
            fetch(`${url}/user/${useId}`,{
                headers: {  "Content-Type": "application/json" },
            }).then((res)=>{return res.json()}).then(response=>{
                if(response.status===true){
                    setUser(response.data)
                    console.log(response)
                }
            })
        }
       
    })

    const closeModal = () => {
        setModalVisible(false);
        setTimeout(() => setIsModalOpen(false), 800);
      };
    return (
        <>
        <div className="lg:flex justify-between max-sm:px-5">
        <NavLink
                    to=""
                    className={({ isActive }) =>
                        `block p-2 lg:ml-20 mt-2 rounded lg:text-[25px] font-medium 
                }`}
                >
                    <QrCodeScannerIcon className="mr-2 mb-1"/> 
                     Your QR Codes
                </NavLink>
                <NavLink to="/new-qr">
                    <button  className="bg-blue-800 hover:bg-blue-900 lg:mr-10 max-sm:px-5 text-white  py-3 px-8 mt-5  rounded-lg"style={{fontWeight:600}}>+ Create New QR</button>
                </NavLink>
                
        </div>
        {user?.qr?.length === 0 ? (
  <div className="text-xl text-center mt-60" style={{ fontWeight: 500 }}>
    NOTHING HERE YET
  </div>
) : (
  <div className="flex flex-wrap gap-10 mb-10 lg:justify-start max-sm:justify-center lg:px-[70px] text-center mt-5">
    {user?.qr?.map((i) => (
      <div key={i._id} className="shadow-2xl">
        {/* Check if user.planId is empty */}
        {user?.planId === "" ? (
          <div className="cursor-pointer" onClick={() => {setModalVisible(true); setIsModalOpen(true)}}>
            <QrCode
              size={180}
              link={i.qrLink}
              color={i.bgColor}
              qrColor={i.color}
              styles={i.qrStyle}
              corners="h"
              logo={i.logo}
              logoBg={i.logoBg}
            />
          </div>
        ) : (
          <Link to={`/designs/${i._id}`}>
            <QrCode
              size={180}
              link={i.qrLink}
              color={i.bgColor}
              qrColor={i.color}
              styles={i.qrStyle}
              corners="h"
              logo={i.logo}
              logoBg={i.logoBg}
            />
          </Link>
        )}

        <Divider />

        <div className="py-2">
          <Tooltip
            title="Visit link"
            placement="top"
            sx={{
              backgroundColor: "blue",
              color: "white",
              padding: "8px",
              borderRadius: "4px",
              fontSize: "14px",
            }}
          >
            <Link
              className="text-black text-[15px] font-medium"
              style={{ fontWeight: 500 }}
              to={i.qrLink}
              target="_blank"
            >
              <h1>{i.qrLink.substring(0, 30)}</h1>
            </Link>
          </Tooltip>
          <Divider className="py-1" />
          <h1
            className="uppercase font-medium text-[15px] text-[#004AAD] py-2"
            style={{ fontWeight: 500 }}
          >
            {i.appName.substring(0, 20)}
          </h1>
        </div>

        {/* Modal Implementation */}
        {isModalOpen && (
          <div
            className={`fixed inset-0 bg-gray-400 bg-opacity-80 flex items-center justify-center z-50 transition-opacity duration-300 ${
              modalVisible ? "opacity-100" : "opacity-0"
            }`}
          >
            <div
              className={`bg-white rounded-3xl lg:w-[45%] p-8 pb-2 mt-0 max-w-3xl mx-auto shadow-lg transition-transform duration-300 ${
                modalVisible ? "translate-y-0" : "translate-y-10"
              }`}
            >
              <h2 className="text-2xl text-center mb-0" style={{ fontWeight: 700 }}>
                Congratulations! Your SYNCQR is ready
              </h2>
              <div className="flex justify-center mb-4">
                <QrCode
                  link={`https://syncqr.ai/${i.title}`}
                  size={250}
                  color={i.bgColor}
                  qrColor={i.color}
                  styles={i.qrStyle}
                  corners="h"
                  logo={i.logo}
                  logoBg={i.logoBg}
                />
              </div>
              <div className="flex justify-center items-center mb-6">
                <a
                  className="text-blue-800 font-semibold bg-blue-50 rounded-2xl px-3 py-1 text-sm"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ fontWeight: 400 }}
                >
                  Your Unique Link
                </a>
               
              </div>
              <p
                style={{ fontWeight: 600 }}
                className="text-[14px] mb-2 text-center"
              >
                You can also customize your QR code with your brand aesthetics
                after purchase
              </p>
              {user.planId === "" ? (
                <div className="flex flex-col space-y-4">
                  <Link
                     to="/home?tab=2"
                    onClick={() => {
                      localStorage.setItem("lastUtl", `/designs/${i._id}`);
                      setTimeout(() =>  window.location.reload(), 800);
                    }}
                 
                  >
                    <button
                      className="bg-blue-800 text-white py-2 w-[90%] lg:ml-10 rounded-lg max-sm:ml-[10px]"
                      style={{ fontWeight: 600 }}
                    >
                      Purchase & download
                    </button>
                  </Link>
                  <button
                    className="mt-4 mb-2 text-gray-500 hover:underline w-[20%] max-sm:px-[50%] flex justify-center lg:ml-56 text-center"
                    onClick={closeModal}
                  >
                    <CloseIcon className="mb-1 mr-2" />
                    Cancel
                  </button>
                </div>
              ) : (
                <div className="flex flex-col space-y-4">
                  <button className="bg-blue-800 text-white py-2 w-[90%] lg:ml-10 rounded-lg max-sm:ml-[10px]">
                    Download in HD
                  </button>
                  <Link to={`/designs/${i._id}`}>
                    <button className="bg-blue-800 text-white max-sm:ml-[10px] py-2 w-[90%] lg:ml-10 rounded-lg">
                      Customize & Design Your QR Code
                    </button>
                  </Link>
                  <button
                    className="mt-4 mb-2 text-gray-500 hover:underline w-[20%] max-sm:px-[50%] flex justify-center lg:ml-56 text-center"
                    onClick={closeModal}
                  >
                    <CloseIcon className="mb-1 mr-2" />
                    Cancel
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    ))}
  </div>
)}

        </>
      );
}

export default Home;