import React, { useEffect, useRef } from "react";
import FeatureCards from "../FeaturesCard/featureCard";

function Features() {
    const cards = [
        {
            id: "feature-1",
            image: "/qr1.png",
            title: "Unified QR Code",
            description: "Create one QR code that works for both Android and iOS app downloads. Seamlessly connect your users to the right app store with a single scan.",
        },
        {
            id: "feature-2",
            image: "/link.png",
            title: "App Install Links",
            description: "Generate a unique link to promote app installs across any platform. Share it anywhere – on your website, social media, or email campaigns.",
        },
        {
            id: "feature-3",
            image: "/announce.png",
            title: "Seamless Integration",
            description: "Easily integrate QR codes into your marketing materials and app promotions.",
        },
        {
            id: "feature-4",
            image: "/verified.png",
            title: "Secure and Reliable",
            description: "Ensure your users' data is protected with our top-notch security features.",
        },
        {
            id: "feature-5",
            image: "/analytic.png",
            title: "Detailed Analytics",
            description: `Track scans, clicks, and user interactions in real-time. Gain valuable insights into user engagement.[ Coming Soon ]`,
        },
        {
            id: "feature-6",
            image: "/light.png",
            title: "High Security",
            description: "Protect your users' data with top-notch security features. Trust in the reliability and safety of SYNCR.AI.",
        },
    ];

    const cardRefs = useRef([]);
    const anchorRef = useRef(null);
    const paragraphRef = useRef(null);
    const headingRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add("animate-fade-in"); 
                    }
                });
            },
            { threshold: 0.2 }
        );
    
        // Observe each element if it exists
        if (anchorRef.current) observer.observe(anchorRef.current);
        if (paragraphRef.current) observer.observe(paragraphRef.current);
        if (headingRef.current) observer.observe(headingRef.current);
        cardRefs.current.forEach((ref) => {
            if (ref) observer.observe(ref);
        });
    
        return () => {
            // Unobserve each element if it exists
            if (anchorRef.current) observer.unobserve(anchorRef.current);
            if (paragraphRef.current) observer.unobserve(paragraphRef.current);
            if (headingRef.current) observer.unobserve(headingRef.current);
            cardRefs.current
                .filter((ref) => ref) // Filter out null or undefined elements
                .forEach((ref) => observer.unobserve(ref));
        };
    }, []);
    

    return (
        <>
            <section id="features">
                <div className="flex mt-16 items-center justify-center">
                    <a
                        ref={anchorRef}
                        className="bg-blue-50 rounded-3xl text-[20px] text-blue-800 font-medium px-2 py-2 opacity-0 transform scale-75 transition-all duration-1000"
                        style={{ fontWeight: 500 }}
                    >
                        WHAT WE CAN DO FOR YOU?
                    </a>
                </div>

                <div>
                    <p
                        ref={headingRef}
                        className="flex mt-10 items-center justify-center leading-none text-[50px] max-sm:text-[30px] max-sm:leading-10 text-center opacity-0 transform scale-75 transition-all duration-1000"
                        style={{ fontWeight: 700 }}
                    >
                        Explore SYNCQR.AI's Powerful Features<br />
                        Effortless QR Code Generation for Android and iOS
                    </p>

                    {/* <p
                        ref={paragraphRef}
                        className="flex mt-6 items-center justify-center text-lg text-center opacity-0 transform translate-x-[-20px] transition-all duration-1000"
                        style={{ fontWeight: 400 }}
                    >
                        Discover how our platform can revolutionize your QR code strategy with seamless integration and top-notch security.
                    </p> */}

                    <div className="mt-20 flex flex-wrap lg:gap-20 max-sm:gap-5 max-sm:px-5 lg:pr-40 lg:pl-40 items-center justify-center">
                        {cards.map((card, index) => (
                            <div
                                key={index}
                                id={card.id}
                                ref={(el) => (cardRefs.current[index] = el)}
                                className="card transform transition-transform duration-1000 ease-in-out scale-75 opacity-0"
                            >
                                <FeatureCards card={card} />
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            <style>
                {`
                    .animate-fade-in {
                        transform: scale(1);
                        opacity: 1;
                    }
                `}
            </style>
        </>
    );
}

export default Features;
