import React, { useState } from 'react';
import { json, NavLink } from "react-router-dom";
import SupportAgentSharpIcon from '@mui/icons-material/SupportAgentSharp';
import PositionedSnackbar from '../SnackBar';
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { url } from '../../urlConfig';
const ContactSupport = () => {
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarVertical, setSnackbarVertical] = React.useState('bottom');
  const [snackbarHorizontal, setSnackbarHorizontal] = React.useState('right');
  const [purpose,setPurpose]=React.useState("")
  const [email,setEmail]=React.useState("")
  const [description,setDescription]=useState("")
  const handleClick = (vertical, horizontal) => () => {
    setSnackbarOpen(true);
    setSnackbarVertical(vertical);
    setSnackbarHorizontal(horizontal);
  };

  const handleClose = () => {
    setSnackbarOpen(false);
  };

function handleSubmit(){
  const formData ={purpose,email,description}
   if(purpose===""){
    toast.error("Please select your purpose")
   } if(email===""){
    toast.error("Email is required")
   }if(description===""){
    toast.error("Enter your message")
   }else{
    fetch(`${url}/contact-support`,{
      method:"POST",
      headers: {  "Content-Type": "application/json" },
      body:JSON.stringify(formData)
    }).then((res)=>{return res.json()})
    .then(response=>{
      if(response.status===true){
        toast.success(response.message)
        setPurpose("")
        setEmail("")
        setDescription("")
      }
    })
   }
}

  return (
    <>

    <div className='lg:flex justify-between max-sm:px-5'>
    <h2 className="lg:flex  lg:text-[25px] lg:ml-10 mt-5  text-black lg:mb-6  items-center"style={{fontWeight:500}}>
        <SupportAgentSharpIcon className=''/> &nbsp; Let us know how we can help you
        </h2>
        {localStorage.getItem("isLogin") ? 
        <NavLink to="/new-qr">
        <button  className="bg-blue-800 hover:bg-blue-900 lg:mr-10 text-white font-semibold py-3 px-8 mt-5  rounded-lg"style={{fontWeight:600}}>+ Create New QR</button>
    </NavLink>
    :
    <NavLink to="/login">
    <button  className="bg-blue-800 hover:bg-blue-900 lg:mr-10 text-white font-semibold py-3 px-8 mt-5  rounded-lg"style={{fontWeight:600}}>+ Create New QR</button>
</NavLink>
      }
        
    </div>
    <ToastContainer/>
    <div className="flex justify-center items-center ">
      <div className="lg:w-[50%] max-sm:w-[100%] max-sm:px-4 mt-5 ">
        
        {/* <form> */}
          <div className="mb-4">
            <label className="block text-[14px] mb-2"style={{fontWeight:400}} >
              Purpose
            </label>
            <select
              id="purpose"
              className="w-full p-3  border-b-2 focus:outline-none focus:border-blue-500 text-gray-800 bg-gray-100"
              style={{fontWeight:400}}
              value={purpose}
              onChange={(e)=>{setPurpose(e.target.value)}}
            >
              <option value="Please select one">Please select one</option>
              <option value="New Feature Suggestion">New Feature Suggestion</option>
              <option value="Bug Report">Bug Report</option>
              <option value="Need Help & Support">Need Help & Support</option>
              <option value="Discuss Enterprise Plan">Discuss Enterprise Plan</option>
              <option value="Other">Other</option>
            </select>
          </div>
          <div className="mb-4"style={{fontWeight:400}}>
            <label className="block text-[14px] mb-2" htmlFor="email">
              Contact Email
            </label>
            <input
              type="email"
              id="email"
              className="w-full p-3  focus:outline-none border-b-2 focus:border-blue-500  text-gray-700 bg-gray-100"
              placeholder="User registered email prefilled with option to edit"
              value={email}
              onChange={(e)=>{setEmail(e.target.value)}}
            />
          </div>
          <div className="mb-5 mt-10"style={{fontWeight:400}}>
            <label className="block text-[14px]  mb-2" htmlFor="message">
              How can we help you?
            </label>
            <textarea
              id="message"
              className="w-full p-3  border-b-2  focus:outline-none focus:border-blue-500 text-gray-700 bg-gray-100"
              rows="3"
              placeholder="Start typing here..."
              value={description}
              onChange={(e)=>{setDescription(e.target.value)}}
            ></textarea>
          </div>
          <button
            // type="submit"
            className="w-full bg-blue-800 text-white p-3 rounded-lg hover:bg-blue-700"
            onClick={handleSubmit}
            style={{fontWeight:600}}
          >
            Send
          </button>
        {/* </form> */}
      </div>

    </div>
   
    </>
  );
};

export default ContactSupport;
;
