import React, { useEffect, useState } from "react";
import { useRef } from 'react';
import { useNavigate } from "react-router-dom";

function Brands(){

    const headingRef = useRef(null);
    const anchorRef = useRef(null);
    const stepRefs = useRef([]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add("animate-fade-in");
                    }
                });
            },
            { threshold: 0.2 }
        );

        // Observe heading and anchor elements
        if (headingRef.current) observer.observe(headingRef.current);
        if (anchorRef.current) observer.observe(anchorRef.current);

        // Observe each step element
        stepRefs.current.forEach((ref) => {
            if (ref) observer.observe(ref);
        });

        // Cleanup observer on component unmount
        return () => {
            if (headingRef.current) observer.unobserve(headingRef.current);
            if (anchorRef.current) observer.unobserve(anchorRef.current);
            stepRefs.current.forEach((ref) => {
                if (ref) observer.unobserve(ref);
            });
        };
    }, []);

    return(
        <>
        
        <div>
            <section id="brands">
                    <div
                     ref={anchorRef}
                    className="flex items-center justify-center opacity-0 transform scale-75 transition-all duration-1000  ">
                        <a className="bg-blue-50 rounded-[20px] text-xl text-blue-800 font-medium px-2  py-2 mt-10" style={{ fontWeight: 500 }}>BRANDS USING SYNCQR.AI</a>
                    </div>
                    <div
                    ref={headingRef}
                    className=" opacity-0 transform scale-75 transition-all duration-1000 "
                    >
                        <h2 
                       
                        className="text-[50px] font-bold text-center mt-10 max-sm:text-[30px] max-sm:leading-10  " style={{ fontWeight: 700 }}>Trusted By Innovators</h2>
                        <p
                        
                        className=" text-gray-600 text-center lg:text-[20px] max-sm:text-[20px] leading-7 mt-10 lg:mb-40 " style={{ fontWeight: 400 }}>Join top brands who trust SYNCQR.AI for seamless app downloads and user engagment.Our clients range<br /> from startups to establish businesses,all benefiting from our smart QR code technology.</p>
                    </div>
                    {/* <div className="flex  text-[24px] text-gray-400 mt-10 ms-96 cursor-pointer space-x-20 pl-14" style={{ fontWeight: 700 }}>
                        <h1 className="hover:text-black">SHELLS</h1>
                        <h1 className="hover:text-black">SmartFinder</h1>
                        <h1 className="hover:text-black">Zoomer</h1>
                        <h1 className="hover:text-black">ArtVenue</h1>
                    </div> */}
                    </section>
                </div>
    

        </>
    )

   

}

export default Brands;