import React, { useEffect, useState } from "react";
import NavbarCreatePage from "../../Components/Navbar2/Navbar2";
import Slider from "../../Components/Slider";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import { Link, useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { url } from "../../urlConfig";
import QrCode from "../../Components/QrCode";
import LogoutIcon from "@mui/icons-material/Logout";
import PositionedSnackbar from "../../Components/SnackBar";
// import { Snackbar } from "@mui/material";
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
function CreateQR() {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState("mobileApp");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [copyMessage, setCopyMessage] = useState("");
  const [appleUrl, setAppleUrl] = useState("");
  const [googleUrl, setGoogleUrl] = useState("");
  const [linkUrl, setLinkUrl] = useState("");
  const [title, setTitle] = useState("");
  const [errors, setErrors] = useState({});
  const [isTaken, setIsTaken] = useState(false);
  const [isAvailable, setIsAvailable] = useState("");
  const [bgColor, setBgColor] = useState("white");
  const [color, setColor] = useState("black");
  const [qrStyle, setQrStyle] = useState("squares");
  const [logo, setLogo] = useState("");
  const [logoBg, setLogoBg] = useState("transparent");
  const [qrId, setQrId] = useState("");
  const [user, setUser] = useState({});

  const userId = localStorage.getItem("userId");

  useEffect(() => {
    fetch(`${url}/user/${userId}`, {
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        return res.json();
      })
      .then((response2) => {
        if (response2.status === true) {
          setUser(response2.data);
        }
      });
  }, []);

  const [state, setState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal, open } = state;

  function handleClick(newState) {
    setState({ ...newState, open: true });
  }

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const validateInputs = () => {
    let errors = {};

    // Validate for Mobile App option
    if (selectedOption === "mobileApp") {
      if (!appleUrl || !/^(https?:\/\/)/i.test(appleUrl)) {
        errors.appleUrl = "Please enter a valid Apple App Store URL.";
      }
      if (!googleUrl || !/^(https?:\/\/)/i.test(googleUrl)) {
        errors.googleUrl = "Please enter a valid Google Play Store URL.";
      }
    }

    // Validate for Link option
    if (selectedOption === "link") {
      if (!linkUrl || !/^(https?:\/\/)/i.test(linkUrl)) {
        errors.linkUrl = "Please enter a valid URL.";
      }
    }

    // Validate title
    if (!title) {
      errors.title = "Please enter a title.";
    }

    if (isAvailable === "taken") {
      errors.title = "This name is already used";
    }
    return errors;
  };

  function handleLogOut() {
    const res = window.confirm("Are you sure to logout");
    if (res === true) {
        localStorage.removeItem("token");
        localStorage.removeItem("isLogin");
        localStorage.removeItem("userId");
        navigate("/login");
    }
}

  const handleGenerateClick = () => {
    const validationErrors = validateInputs();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    let qrLink = `https://syncqr.ai/${title}/001`;
    let formData = {
      userId,
      title,
      appleUrl,
      googleUrl,
      linkUrl,
      qrLink,
      selectedOption,
      bgColor,
      color,
      qrStyle,
      logo,
      logoBg,
    };
    console.log(formData);
    fetch(`${url}/generate-qr`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(formData),
    })
      .then((res) => {
        return res.json();
      })
      .then((response) => {
        if (response.status === true) {
          setQrId(response.data._id);
          setIsModalOpen(true);
          setTimeout(() => setModalVisible(true), 10);
       
        } else {
         toast.error(response.message)
        }
      });
  };

  const closeModal = () => {
    setModalVisible(false);
    setTimeout(() => setIsModalOpen(false), 800);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(`https://syncqr.ai/${title}`);
    setCopyMessage("Copied!");
    setTimeout(() => setCopyMessage(""), 1000);
  };

  function handleChangeTitle(e) {
    setIsAvailable("");
    setTitle((x) => (x = e.target.value));
    console.log(e.target.value);
    let appName = e.target.value;
    if (e.target.value !== "") {
      validateInputs();
      fetch(`${url}/qr-by-name/${appName}`, {
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => {
          return res.json();
        })
        .then((response) => {
          if (response.status === true) {
            setIsTaken(false);
            console.log(response);
            validateInputs();
            errors.title = "";
            setIsAvailable(response.message);
          } else {
            console.log(response);
            setIsTaken(true);
            validateInputs();
            errors.title = "this name is already taken";
            setIsAvailable(response.message);
          }
        });
    } else {
      setIsAvailable("");
    }
  }

    return (
        <>
        <ToastContainer/>
        <div className="flex justify-between font-bold pl-10 pb-4 text-2xl pt-5 border-b-2 sticky top-0 bg-white ">
            {/* <PositionedSnackbar/> */}
                <Link to="/home">
                <h2>SYNCQR.AI</h2></Link>
                <a onClick={handleLogOut} className="text-[16px] font-bold mr-4 hover:text-blue-800 flex items-center cursor-pointer">
                Log Out
                <LogoutIcon className='ml-2' style={{ fontSize: "25px" }} />
            </a>
            </div>
            <div className="bg-slate-50 w-full">
            
                    <div className="">
                        <div className="lg:max-w-2xl lg:ml-10 max-sm:ml-5">
                            <h1 className="text-4xl pt-10  mb-6"style={{fontWeight:700}}>Create a new QR</h1>

            {/* Step 1: Selecting QR Type */}
            <div className="mb-8">
              <h2 className="text-lg font-semibold mb-4"style={{fontWeight:500}}>
                Step 1: Creating SYNCQR for
              </h2>
              <div className="inline-flex border border-black overflow-hidden"style={{fontWeight:500}}>
                <label
                  className={`flex items-center px-4 py-2 border-r border-black cursor-pointer ${
                    selectedOption === "mobileApp" ? "bg-blue-50" : "bg-white"
                  }`}
                  onClick={() => setSelectedOption("mobileApp")}
                >
                  <input
                    type="radio"
                    name="qrType"
                    value="mobileApp"
                    checked={selectedOption === "mobileApp"}
                    onChange={(e) => setSelectedOption(e.target.value)}
                    className="hidden"
                  />
                  <div
                    className={`w-4 h-4 mr-2 border-2 rounded-full flex justify-center items-center ${
                      selectedOption === "mobileApp"
                        ? "border-blue-600"
                        : "border-gray-400"
                    }`}
                  >
                    {selectedOption === "mobileApp" && (
                      <div className="w-2 h-2 bg-blue-800 rounded-full"></div>
                    )}
                  </div>
                  <span className="text-sm">Mobile App</span>
                </label>

                <label
                  className={`flex items-center px-4 py-2 cursor-pointer ${
                    selectedOption === "link" ? "bg-blue-50" : "bg-white"
                  }`}
                  onClick={() => setSelectedOption("link")}
                >
                  <input
                    type="radio"
                    name="qrType"
                    value="link"
                    checked={selectedOption === "link"}
                    onChange={(e) => setSelectedOption(e.target.value)}
                    className="hidden"
                  />
                  <div
                    className={`w-4 h-4 mr-2 border-2 rounded-full flex justify-center items-center ${
                      selectedOption === "link"
                        ? "border-blue-600"
                        : "border-gray-400"
                    }`}
                  >
                    {selectedOption === "link" && (
                      <div className="w-2 h-2 bg-blue-800 rounded-full"></div>
                    )}
                  </div>
                  <span className="text-sm">Link</span>
                </label>
              </div>
            </div>

            {/* Step 2: Adding Destination URLs */}
            {selectedOption === "mobileApp" ? (
              <div className="mt-4 mb-8"style={{fontWeight:500}}>
                <h2 className="text-lg  mb-4">
                  Step 2: Add destination URLs
                </h2>
                <div className="mb-4">
                  <label
                  style={{fontWeight:400}}
                    className="block text-sm mb-2 font-semibold"
                    htmlFor="appleUrl"
                  >
                    Apple App Store URL
                  </label>
                  <input
                    type="text"
                    id="appleUrl"
                    value={appleUrl}
                    onChange={(e) => setAppleUrl(e.target.value)}
                    placeholder="Paste your Apple app URL here"
                    className="w-full p-3 bg-slate-100  border-b-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    style={{fontWeight:400}}
                  />
                  {errors.appleUrl && (
                    <p className="text-red-600 text-sm mt-2">
                      {errors.appleUrl}
                    </p>
                  )}
                </div>
                <div className="mb-4">
                  <label
                    className="block  text-sm mb-2"
                    htmlFor="googleUrl"
                    style={{fontWeight:400}}
                  >
                    Google Play Store URL
                  </label>
                  <input
                    type="text"
                    id="googleUrl"
                    value={googleUrl}
                    onChange={(e) => setGoogleUrl(e.target.value)}
                    placeholder="Paste your Android app URL here"
                    className="w-full p-3 bg-slate-100 border-b-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    style={{fontWeight:400}}
                  />
                  {errors.googleUrl && (
                    <p className="text-red-600 text-sm mt-2">
                      {errors.googleUrl}
                    </p>
                  )}
                </div>
              </div>
            ) : (
              <div className="mt-4 mb-8">
                <h2 className="text-lg font-semibold mb-4">
                  Step 2: Add destination URLs
                </h2>
                <div className="mb-4">
                  <label className="block text-sm mb-2" htmlFor="linkUrl" style={{fontWeight:500}}>
                    Where would you like the users to redirect after scanning?
                  </label>
                  <input
                    type="text"
                    id="linkUrl"
                    value={linkUrl}
                    onChange={(e) => setLinkUrl(e.target.value)}
                    placeholder="Paste your URL here"
                    style={{fontWeight:400}}
                    className="w-full p-3 bg-slate-100 border-b-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                  {errors.linkUrl && (
                    <p className="text-red-600 text-sm mt-2">
                      {errors.linkUrl}
                    </p>
                  )}
                </div>
              </div>
            )}

            {/* Step 3: Adding Title */}
            <div className="mb-8">
              <h2 className="text-lg font-semibold mb-4" style={{fontWeight:500}}>
                Step 3: Give your code a title to track analytics in your
                dashboard later
              </h2>
              <div className="mb-4">
                <p className="text-sm font-semibold mt-1 mb-2">
                  https://syncqr.ai/{title} {isAvailable}
                </p>
                <input
                  type="text"
                  value={title.trim(" ")}
                  onChange={(e) => {
                    handleChangeTitle(e);
                    
                  }}
                  style={{fontWeight:400}}
                  placeholder="Enter here"
                  className="w-full p-3 bg-slate-100 border-b-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                {errors.title && (
                  <p className="text-red-600 text-sm mt-2">{errors.title}</p>
                )}
              </div>
            </div>

            {/* Generate QR Code Button */}
            <button
              className="w-full mb-4 bg-blue-800 text-white py-2 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
              onClick={handleGenerateClick}
              style={{fontWeight:600}}
            >
              Generate QR Code
            </button>

            {/* Modal */}
            {isModalOpen && (
              <div
                className={`fixed inset-0 bg-gray-400 bg-opacity-80 flex items-center justify-center z-50 transition-opacity duration-300 ${
                  modalVisible ? "opacity-100" : "opacity-0"
                }`}
              >
                <div
                  className={`bg-white rounded-3xl lg:w-[45%] p-8 pb-2 mt-0 max-w-3xl mx-auto shadow-lg transition-transform duration-300 ${
                    modalVisible ? "translate-y-0" : "translate-y-10"
                  }`}
                >
                  <h2 className="text-2xl text-center mb-0" style={{fontWeight:700}}>
                    Congratulations! Your SYNCQR is ready
                  </h2>
                  <div className="flex justify-center mb-4">
                    <QrCode
                      link={`https://syncqr.ai/${title}`}
                      size={250}
                      color={bgColor}
                      qrColor={color}
                      styles={qrStyle}
                      corners={"h"}
                      logo={logo}
                      logoBg={logoBg}
                    />
                  </div>
                  <div className="flex justify-center items-center mb-6">
                    <a
                      className="text-blue-800 font-semibold bg-blue-50 rounded-2xl px-3 py-1 text-sm"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{fontWeight:400}}
                    >
                      Your Unique Link
                    </a>
                    <button
                      className="ml-3 mt-1 font-semibold mb-1 flex hover:underline items-center"
                      onClick={handleCopy}
                      style={{fontWeight:400}}
                    >
                      https://syncqr.ai/{title}
                      <ContentCopyOutlinedIcon className="text-blue-700 ml-2 " />
                    </button>
                    {copyMessage && (
                      <span className="text-blue-500">{copyMessage}</span>
                    )}
                  </div>
                  <p  style={{fontWeight:600}} className="text-[14px] mb-2 text-center">You can also customize your QR code with your brand aesthetics after purchase</p>
                  {user.planId === "" ? (
                    <div className="flex flex-col space-y-4">
                        <Link onClick={()=>{localStorage.setItem("lastUtl",`/designs/${qrId}`)}} to="/home?tab=2" >
                        <button className="bg-blue-800 text-white py-2 w-[90%] lg:ml-10 rounded-lg max-sm:ml-[10px]" style={{fontWeight:600}}>
                       Purchse & download
                      </button>
                        </Link>
                     
                      
                      <button
                        className="mt-4 mb-2 text-gray-500 hover:underline w-[20%] max-sm:px-[50%] flex justify-center lg:ml-56 text-center"
                        onClick={closeModal}
                      >
                        <CloseIcon className="mb-1 mr-2" />
                        Cancel
                      </button>
                    </div>
                  ) : (
                    <div className="flex flex-col space-y-4">
                      <button className="bg-blue-800 text-white py-2 w-[90%] lg:ml-10 rounded-lg max-sm:ml-[10px]">
                        Download in HD
                      </button>
                      <Link to={`/designs/${qrId}`}>
                        {" "}
                        <button className="bg-blue-800 text-white max-sm:ml-[10px] py-2 w-[90%] lg:ml-10 rounded-lg">
                          Customize & Design Your QR Code
                        </button>
                      </Link>
                      <button
                        className="mt-4 mb-2 text-gray-500 hover:underline w-[20%] max-sm:px-[50%] flex justify-center lg:ml-56 text-center"
                        onClick={closeModal}
                      >
                        <CloseIcon className="mb-1 mr-2" />
                        Cancel
                      </button>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateQR;
