import React, { useState } from "react";
import LogoutIcon from '@mui/icons-material/Logout';
import { Link, redirect, useNavigate } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import { Drawer, List, ListItem, ListItemText, IconButton, Divider } from '@mui/material';

function NavbarCreatePage({ currentTab, setCurrentTab }) {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const navigate = useNavigate();

    function handleClickGenerate() {
        if (localStorage.getItem("isLogin")) {
            navigate("/home?tab=0");
        } else {
            navigate("/login");
        }
    }

    function handleLogOut() {
        const res = window.confirm("Are you sure to logout");
        if (res === true) {
            localStorage.removeItem("token");
            localStorage.removeItem("isLogin");
            localStorage.removeItem("userId");
            navigate("/login");
        }
    }

    const toggleDrawer = (open) => () => {
        setDrawerOpen(open);
    };

    const drawerContents = (
        <div role="presentation"  onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)} className="w-[250px] flex flex-col h-full">
           <Link to={"#"}>
            <div className="flex   p-4">
                <img src="/Navbar1.png" alt="logo" style={{ height: "30px", width: "28px" }} className="mb-2" />
                <h2 className="text-2xl font-bold text-blue-800">SYNCQR.AI</h2>
            </div>
            </Link>
            <Divider />
            <List>
                {localStorage.getItem("isLogin") && (
                    <>
                        <ListItem  onClick={() => navigate("/home?tab=0")}>
                            <ListItemText primary="Home" className="cursor-pointer" onClick={() => setCurrentTab(0)} />
                        </ListItem>
                        <ListItem  onClick={() => navigate("/home?tab=1")}>
                            <ListItemText primary="Contact Support" className="cursor-pointer"  onClick={() => setCurrentTab(1)}/>
                        </ListItem>
                        <ListItem  onClick={() => navigate("/home?tab=2")}>
                            <ListItemText primary="Your Subscription" className="cursor-pointer"  onClick={() => setCurrentTab(2)}/>
                        </ListItem>
                    </>
                )}
            </List>
            <div className="mt-auto p-4">
                {localStorage.getItem("isLogin") && (
                    <div className="flex   cursor-pointer" onClick={handleLogOut}>
                        <LogoutIcon style={{ fontSize: "25px" }} />
                        <span className="ml-2 text-[16px] font-bold hover:text-blue-800">Log Out</span>
                    </div>
                )}
            </div>
        </div>
    );

    return (
        <div className="p-5 border-b-2 shadow-sm flex justify-between items-center sticky top-0 bg-white">
            <Link to={"/"}>
            <div className="font-bold flex lg:ml-7 text-[24px] text-blue-800 lg:mr-60">
                <img src="/Navbar1.png" alt="logo" style={{ height: "30px", width: "28px" }} className="mr-2 mt-1" />
                <h2>SYNCQR.AI</h2>
            </div>
            </Link>
            <div className="flex text-[20px] space-x-20 lg:mr-90 max-sm:hidden"style={{fontWeight:500}}>
                {localStorage.getItem("isLogin") && (
                    <>
                        <button
                            onClick={() => setCurrentTab(0)}
                            className={`font-medium hover:underline flex items-center ${currentTab === 0 ? 'underline text-blue-800' : ''}`}
                        >
                            Home
                        </button>
                        <button
                            onClick={() => setCurrentTab(1)}
                            className={`font-medium hover:underline flex items-center ${currentTab === 1 ? 'underline text-blue-800' : ''}`}
                        >
                            Contact Support
                        </button>
                        <button
                            onClick={() => setCurrentTab(2)}
                            className={`font-medium hover:underline flex items-center ${currentTab === 2 ? 'underline text-blue-800' : ''}`}
                        >
                            Your Subscription
                        </button>
                    </>
                )}
            </div>
            <div className="lg:hidden">
                <IconButton onClick={toggleDrawer(true)}>
                    <MenuIcon />
                </IconButton>
                <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
                    {drawerContents}
                </Drawer>
            </div>

 {localStorage.getItem("isLogin") && 
<div className="max-sm:hidden">
<img src="/Navbar1.png" alt="logo" style={{ height: "38px", width: "38px" }} className="  bg-blue-100 rounded-full p-1 ml-44 -mb-1 cursor-pointer  " />
</div>
}
{localStorage.getItem("isLogin") && 
            <h1   className="text-[16px] max-sm:hidden font-medium mr-4 cursor-pointer  hover:text-blue-800 flex items-center" style={{fontWeight:500}} onClick={handleLogOut}>
                Log Out
                <LogoutIcon className='ml-2' style={{ fontSize: "25px" }}/>
            </h1>
}
        </div>
    );
}

export default NavbarCreatePage;
