import React, { useRef } from 'react';

const QrCodeGenerator = () => {
    const canvasRef = useRef(null);

    const generateQRCode = (text) => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        const size = 20; // Size of each QR code module

        // Clear the canvas
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        // A simple QR code generator for demonstration purposes
        const qrData = [
            [1, 0, 0],
            [0, 1, 1],
            [1, 1, 0]
        ];

        // Loop through qrData to draw each module
        for (let y = 0; y < qrData.length; y++) {
            for (let x = 0; x < qrData[y].length; x++) {
                ctx.fillStyle = qrData[y][x] === 1 ? 'black' : 'white';
                ctx.fillRect(x * size, y * size, size, size);
            }
        }
    };

    const handleGenerate = () => {
        const inputText = document.getElementById('textInput').value;
        generateQRCode(inputText);
    };

    return (
        <div className='flex justify-center w-full'>
            <canvas ref={canvasRef} width={200} height={200}></canvas>
            <input type="text" id="textInput" placeholder="Enter text" />
            <button onClick={handleGenerate}>Generate QR Code</button>
        </div>
    );
};

export default QrCodeGenerator;
