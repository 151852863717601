import React, { useEffect, useState } from "react";
import { useRef } from 'react';
import { useNavigate } from "react-router-dom";
import OtpModal from "../OtpModal";
import SubscriptionDialog from "../Home/SubscriptionDialog";

function GetStarted(){

    const headingRef = useRef(null);
    const anchorRef = useRef(null);
    const stepRefs = useRef([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const navigate = useNavigate();
    function handleClickGenerate() {
        if (localStorage.getItem("isLogin")) {
            navigate("/home")
        } else {
            navigate("/login")
        }
    }

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add("animate-fade-in");
                    }
                });
            },
            { threshold: 0.2 }
        );

        // Observe heading and anchor elements
        if (headingRef.current) observer.observe(headingRef.current);
        if (anchorRef.current) observer.observe(anchorRef.current);

        // Observe each step element
        stepRefs.current.forEach((ref) => {
            if (ref) observer.observe(ref);
        });

        // Cleanup observer on component unmount
        return () => {
            if (headingRef.current) observer.unobserve(headingRef.current);
            if (anchorRef.current) observer.unobserve(anchorRef.current);
            stepRefs.current.forEach((ref) => {
                if (ref) observer.unobserve(ref);
            });
        };
    }, []);

    return(
        <>
        <div 
        ref={headingRef}
        className="lg:flex opacity-0 transform scale-75 transition-all duration-1000 ">
                    <div className="lg:w-[57%] max-sm:w-[100%] lg:p-16 max-sm:p-2 lg:ml-20 lg:mt-10">
                        <h2 className="text-[50px] max-sm:text-[30px] max-sm:leading-10 " style={{ fontWeight: 700 }}>Get Started Today!</h2>

                        <p className="lg:text-[25px] max-sm:text-[20px] text-gray-600 mb-6 mt-8 text-start lg:leading-8 max-sm:leading-7" style={{ fontWeight: 400 }}>Sign up now to create your first QR code and transform how your users download your app . Experience the ease and efficiency of SYNCQR.AI </p>
                    </div>
                    <div>
                        <div className="lg:mt-36 space-y-4 max-sm:flex max-sm:flex-wrap justify-center ">
                            <div>
                                <button className="bg-blue-800 hover:bg-blue-900 text-md  text-white  py-3 px-20  lg:ml-40 rounded-xl border-2 " onClick={()=>{setIsModalOpen(true)}} style={{ fontWeight: 600 }}>
                                    View Pricing
                                </button>

                            </div>
                            <button className="bg-blue-800 text-white hover:bg-blue-900 text-md   py-3 px-8 lg:ml-40 rounded-xl  border-2 " onClick={handleClickGenerate} style={{ fontWeight: 600 }}>Create Your First QR Code</button>
                        </div>
                    </div>
                </div>
                {isModalOpen && (
                <SubscriptionDialog
                
                    isOpen={isModalOpen}
                    setIsOpen={setIsModalOpen}
                    
                />
            )}
        </>
    )

   

}

export default GetStarted;