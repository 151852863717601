import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import { Drawer, List, ListItem, ListItemText, IconButton, Divider } from '@mui/material';

function Navbar() {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [navVisible, setNavVisible] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        // Trigger the navbar visibility with a slight delay for animation
        setTimeout(() => {
            setNavVisible(true);
        }, 200); // Adjust the delay time as needed
    }, []);


    function handleClickGenerate() {
        if (localStorage.getItem("isLogin")) {
            navigate("/home");
        } else {
            navigate("/login");
        }
    }

    function handleLogOut() {
        const res = window.confirm("Are you sure to logout?");
        if (res === true) {
            localStorage.removeItem("token");
            localStorage.removeItem("isLogin");
            localStorage.removeItem("userId");
            navigate("/login");
        }
    }

    const toggleDrawer = (open) => () => {
        setDrawerOpen(open);
    };

    const drawerContents = (
        <div role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)} className="w-[250px] flex flex-col h-full">
            <div className="flex  text-2xl p-4">
                <img src="/Navbar1.png" alt="logo" style={{ height: "30px", width: "28px" }} className="mb-2" />
                <h2 className="font-bold text-blue-800">SYNCQR.AI</h2>
            </div>
            <Divider />
            <List>
                <ListItem button onClick={() => navigate("/")}>
                    <ListItemText primary="Home" />
                </ListItem>
                <ListItem button onClick={() => navigate("/login")}>
                    <ListItemText primary="Login" />
                </ListItem>
                <ListItem button onClick={() => navigate("/home")}>
                    <ListItemText primary="Pricing" />
                </ListItem>
            </List>
            <div className="mt-auto p-4">
                <button className="bg-blue-800 hover:bg-blue-700 text-white font-semibold py-3 px-7 w-full rounded-lg" onClick={handleClickGenerate}>
                    Generate QR Code
                </button>
                {localStorage.getItem("isLogin") && (
                    <div className="mt-4 flex items-center justify-center cursor-pointer" onClick={handleLogOut}>
                        <span className="text-[16px] font-bold hover:text-blue-800">Log Out</span>
                    </div>
                )}
            </div>
        </div>
    );

    return (
        <>
            <div className="fixed top-0 left-0 right-0 z-50 bg-white shadow-md">
                <div className="flex max-sm:justify-between items-center text-black py-4">
                    <a href={"/"} >
                    <div className="font-bold flex max-sm:justify-between max-sm:px-5 lg:ml-7 text-[24px] text-blue-800 lg:mr-48">
                        <img src="/Navbar1.png" alt="logo" style={{ height: "30px", width: "28px" }} className="mr-2 mt-1" />
                        <h2>SYNCQR.AI</h2>
                    </div>
                    </a>
                    
                    <div className=" text-center text-[16px] space-x-10 max-sm:hidden ml-80"style={{fontWeight:500}}>
                        <a href="#features">Features</a>
                        <a href="#working">How it works</a>
                        <a href="#testimonials">Testimonials</a>
                        {!localStorage.getItem("isLogin") && <a href="#" onClick={() => { navigate("/login") }} >Login</a>}
                        {localStorage.getItem("isLogin") && <a href="#">Profile</a>}
                    </div>
                    <div className="lg:hidden ">
                        <IconButton onClick={toggleDrawer(true)}>
                            <MenuIcon sx={{color:"black",zIndex:99}}/>
                        </IconButton>
                        <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
                            {drawerContents}
                        </Drawer>
                    </div>
                    <div className="hidden lg:block ml-auto">
                        <button className="bg-blue-800 hover:bg-blue-700 text-white  py-3 px-7 mr-8 rounded-lg"style={{fontWeight:600}} onClick={handleClickGenerate}>Generate QR Code</button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Navbar;
