import React, { forwardRef } from 'react';
import { QRCode } from 'react-qrcode-logo';

const QrCode = forwardRef(({ color, qrColor, styles, link, logo, size }, ref) => (
  <div style={{ textAlign: 'center', padding: '20px', backgroundColor: '#ffffff' }}>
    <div ref={ref} >
      <QRCode 
        value={link}
        size={size}
        bgColor={color}
        fgColor={qrColor}
        level="Q"
        includeMargin={true}
        qrStyle={styles}
        logoImage={logo}
        logoWidth={60}
        logoHeight={60}
        logoOpacity={0.8}
        eyeRadius={[
          {
            outer: [10, 10, 0, 0],
            inner: [0, 0, 10, 10],
          },
          {
            outer: [10, 0, 10, 0],
            inner: [10, 0, 0, 10],
          },
          {
            outer: [0, 10, 0, 10],
            inner: [10, 10, 10, 0],
          },
        ]}
        removeQrCodeBehindLogo={true}
        logoBackgroundColor="transparent"
      />
    </div>
  </div>
));

export default QrCode;
