import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from "react-router-dom";
import { url } from '../../urlConfig';
import moment from 'moment';
import { loadStripe } from '@stripe/stripe-js';
const stripePromise = loadStripe("pk_live_51K2ItSSHrAtPgpQTAyhaaurmwFsh0qN0ZWa7j0b8iAh0kgAcch1quXxn59rbt2MlyJ6Ja4LEqJvU16e7cMsrI84Y00gZXUGbg0");
const SubscriptionDialog = (props) => {
  const [hasActiveSubscription, setHasActiveSubscription] = useState(true); 
  const [plan,setPlan]=useState({})
  const [user,setUser]=useState({})
  const [allPlan,setAllPlan]=useState([])
  const navigate =useNavigate()
  function handleClickGenerate() {
      if (localStorage.getItem("isLogin")) {
          navigate("/new-qr")
      } else {
          navigate("/login")
      }
  }
  const userId =localStorage.getItem("userId")

  const handleSubscriptionClick = async (planName,planId) => {
    if(localStorage.getItem("userId")){
      localStorage.setItem("planName",planName)
      localStorage.setItem("planId",planId)
      const response = await fetch(`${url}/create-checkout-session`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          priceId: 'price_1Puu87SHrAtPgpQTfxpwf6u3',
          email:"rohitkumar.brainbox@gmail.com"
        }),
      });
    
      const session = await response.json();
      const stripe = await stripePromise;
    
      const result = await  stripe.redirectToCheckout({
        sessionId: session.id,
      });
     console.log(result)
    }else{
      navigate("/login")
    }
    
  };

  useEffect(()=>{
    if(userId){
        fetch(`${url}/user/${userId}`,{
            headers: {  "Content-Type": "application/json" },
        }).then((res)=>{return res.json()}).then(response=>{
            if(response.status===true){
                // setUser(response.data)
                if(response.data.planId!==""){
                  setHasActiveSubscription(true)
                  setPlan(response.data.plan)
                  setUser(response.data)
                 console.log(response)
                }else{
                  setHasActiveSubscription(false)
                }
            }
        })
    }else{
      setHasActiveSubscription(false)
    }
   fetch(`${url}/plans`,{
    headers: {  "Content-Type": "application/json" },
   }).then((res)=>{return res.json()})
   .then(response=>{
    if(response.status===true){
      setAllPlan(response.data)
    }
   })
},[])
  return (
    <>
    
    <div className=' fixed inset-0 flex items-center justify-center bg-white bg-opacity-50 ' onClose={()=>{props.setIsOpen(true)}}>
      {/* <div className='flex justify-between '>
        <h2 className="flex lg:px-5 max-sm:px-2 lg:text-[25px] lg:ml-2 mt-5 font-semibold text-black lg:mb-6 items-center"style={{fontWeight:500}}>
          {hasActiveSubscription ? "You have an active subscription" : "You have not any active subscription yet"}
        </h2>
       
      </div> */}
      
      {hasActiveSubscription===true &&
        <div className="flex  flex-wrap mt-5 ">
          <div className="w-full   rounded-lg   mb-10 p-6">
            <div className="flex flex-wrap max-sm:justify-center text-[18px] lg:space-x-5 gap-5">
              <div className="border-2 border-blue-600 bg-white rounded-lg p-4 lg:w-[20%]">
                <h4 className="font-semibold text-[18px]"style={{fontWeight:600}}>Active Plan - {plan?.planName}</h4>
                {plan.planType===0 && <p style={{fontWeight:400}}>{plan.planPrice}$/year</p>}
                {plan.planType===1 && <p style={{fontWeight:400}}>{plan.planPrice}$/month</p>}
              </div>
              <div className="border-2 border-blue-600  text-[18px] bg-white rounded-lg p-4  lg:w-[20%]">
                <h4 className="font-semibold"style={{fontWeight:600}}>Active QR Codes</h4>
                <p style={{fontWeight:400}} >{user?.qrCount}/{plan?.qrPerYear}</p>
              </div>
              <div className="border-2 text-[18px] border-blue-600 bg-white rounded-lg p-4  lg:w-[20%]">
                <h4 className="font-semibold" style={{fontWeight:600}}>Subscription Start Date</h4>
                <p style={{fontWeight:400}}>{moment(user.planCreatedOn).format("MMM Do YY")}</p>
              </div>
            </div>
            <div className="mt-10"style={{fontWeight:500}}>
              <p className="font-medium text-[20px] underline">
                Need more help or QR codes and enterprise plan discussion, mail to us at 
                <a href="mailto:partnerships@syncqr.ai" className="text-blue-800"> partnerships@syncqr.ai</a>
              </p>
              <p className="mt-2 text-[20px] text-red-500 underline font-medium">
                Request subscription cancellation
              </p>
            </div>
          </div>
        </div>
    }
    {hasActiveSubscription===false &&
        <div className="flex justify-center w-full gap-5 flex-wrap mt-28">
          
          {allPlan.map(i=>(
   <div className="lg:w-[60%] max-sm:w-[90%] max-sm:h-[500px] overflow-auto scrollbar-hide   bg-slate rounded-lg  shadow-2xl mb-10 p-6 bg-gray-50">
      <div className='flex  text-lg hover:text-blue-800 hover:underline justify-end cursor-pointer' onClick={()=>{props.setIsOpen(false)}}>
                close
            </div>
   <h3 className="text-[25px] text-center  text-blue-800 mb-4"style={{fontWeight:500}}>
     Simple, Transparent Pricing for Everyone
   </h3>
   <p className=" mb-6 text-center leading-5"style={{fontWeight:400}}>              
     SyncQR.AI offers straightforward pricing with no hidden fees. Enjoy seamless app
     downloads, unlimited QR scans, and premium support, all at an unbeatable value.
   </p>
   <ul className="space-y-4 font-normal">
     <li className="flex items-start">
       <span className="text-green-600 mr-2">✔</span>
       <p style={{fontWeight:400}}><span className='text-[17px] 'style={{fontWeight:700}} >One QR Code for All Devices:</span> Seamlessly direct users to the right app store, whether they’re on Android or iOS.</p>
     </li>
     <li className="flex items-start">
       <span className="text-green-600 mr-2">✔</span>
       <p style={{fontWeight:400}}><span className='text-[17px] 'style={{fontWeight:700}}>Unlimited Scans:</span>  Enjoy unlimited QR code scans with no additional costs.</p>
     </li>
     <li className="flex items-start">
       <span className="text-green-600 mr-2">✔</span>
       <p style={{fontWeight:400}}><span className='text-[17px] 'style={{fontWeight:700}}>No Hidden Advertisements:</span>  We show no ads to your users at all. You own your QR code.</p>
     </li>
     <li className="flex items-start">
       <span className="text-green-600 mr-2">✔</span>
       <p style={{fontWeight:400}}><span className='text-[17px] 'style={{fontWeight:700}}>24/7 Support:</span>  Get round-the-clock customer support to assist with any queries.</p>
     </li>
     <li className="flex items-start">
       <span className="text-green-600 mr-2">✔</span>
       <p style={{fontWeight:400}}><span className='text-[17px] 'style={{fontWeight:700}}>Early Access to New Features:</span>  Be the first to try out new features before they are released to the public.</p>
     </li>
     <li className="flex items-start">
       <span className="text-green-600 mr-2">✔</span>
       <p style={{fontWeight:400}}><span className='text-[17px] 'style={{fontWeight:700}}>Customizable QR Design:</span>  Tailor the look of your QR code to fit your brand’s identity.</p>
     </li>
     <li className="flex items-start">
       <span className="text-green-600 mr-2">✔</span>
       <p style={{fontWeight:400}}><span className='text-[17px] 'style={{fontWeight:700}}>{i.qrPerYear} QR Codes a Year:</span>  Complete flexibility of generating 5 QR codes in a complete year. If you want more, just reach out to us.</p>
     </li>
   </ul>
   <button className=" mt-6 w-full bg-yellow-300 text-[20px] border border-black py-3 rounded-lg hover:bg-yellow-400"style={{fontWeight:600}} onClick={() => handleSubscriptionClick(i.planName,i._id)}>
     Subscribe for {i.planPrice}$/year
   </button>
 </div>
          ))}
       
        </div>
        
      }
      </div>
    </>
  );
};

export default SubscriptionDialog;
