import React, { useEffect, useState } from "react";
import { useRef } from 'react';
import { useNavigate } from "react-router-dom";

function SetupSteps(){

    const headingRef = useRef(null);
    const anchorRef = useRef(null);
    const stepRefs = useRef([]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add("animate-fade-in");
                    }
                });
            },
            { threshold: 0.2 }
        );

        // Observe heading and anchor elements
        if (headingRef.current) observer.observe(headingRef.current);
        if (anchorRef.current) observer.observe(anchorRef.current);

        // Observe each step element
        stepRefs.current.forEach((ref) => {
            if (ref) observer.observe(ref);
        });

        // Cleanup observer on component unmount
        return () => {
            if (headingRef.current) observer.unobserve(headingRef.current);
            if (anchorRef.current) observer.unobserve(anchorRef.current);
            stepRefs.current.forEach((ref) => {
                if (ref) observer.unobserve(ref);
            });
        };
    }, []);

    return(
        <>
    
        <section id="working">
            
            <div className=" p-12 lg:mt-20 max-sm:mt-10 opacity-0 transform scale-75 transition-all duration-1000" ref={headingRef}>
                        <div className="flex items-center justify-center ">
                            <a
                           

                            className={"bg-blue-50 rounded-3xl text-[20px] text-blue-800 font-medium px-2  py-2  "} style={{ fontWeight: 500 }}>HOW  IT  WORKS?</a>
                        </div>
                        <div>
                            <h2 
                        
                            className={"text-[50px] font-bold text-center mt-6 pb-10 max-sm:text-[30px] max-sm:leading-10  "} style={{ fontWeight: 700 }}>Effortless Setup with SYNCQR.AI</h2>
                        </div>
                        <div className="flex max-sm:flex-wrap justify-center">
                            <div className="lg:w-[18%] text-center lg:mt-10 ">
                                <span
                                //  ref={headingRef}
                                class="text-9xl font-bold text-blue-600 drop-shadow-[3px_3px_0px_rgba(0,0,0,1)] ">1</span>
                                <h1 className="text-[20px] font-bold hover:text-blue-800 hover:underline cursor-pointer"><a href="/signup" >Sign up</a></h1>
                                <p className="text-[20px] leading-6 text-gray-600" style={{ fontWeight: 300 }}>Create your account on SYNCQR.AI. It's quick and easy!</p>
                            </div>
                            <div className="mt-20 max-sm:hidden w-[150px]">
                                <img src="https://clipart-library.com/img/1020880.png" />
                            </div>
                            <div className={`lg:w-[21%] text-center lg:mt-10`}>
                                <span class="text-9xl font-bold text-blue-600 drop-shadow-[3px_3px_0px_rgba(0,0,0,1)]">2</span>
                                <h1 className="text-[20px] font-bold hover:text-blue-800 hover:underline cursor-pointer"><a href="/home">Generate QR code Install Link</a></h1>
                                <p className="text-[20px] leading-6 text-gray-600" style={{ fontWeight: 300 }}>Enter your Android and iOS links to Generate a single QR code and a unique app install link. These tools ensure your users are redirected to the correct app store ,No matter what device they are using.</p>
                            </div>
                            <div className="mt-20 max-sm:hidden w-[150px]">
                                <img src="https://clipart-library.com/img/1020880.png" />
                            </div>
                            <div className={`lg:w-[18%] text-center lg:mt-10`}>
                                <span class="text-9xl font-bold text-blue-600 drop-shadow-[3px_3px_0px_rgba(0,0,0,1)]">3</span>
                                <h1 className="text-[20px] font-bold">Share</h1>
                                <p className="text-[20px] leading-6 text-gray-600" style={{ fontWeight: 300 }}>Distribute your QR code and install link through your preffered channels.Use them on your website , social media,marketing materials and Email campaigns.</p>
                            </div>
                            <div className="mt-20 max-sm:hidden w-[150px]">
                                <img src="https://clipart-library.com/img/1020880.png" />
                            </div>
                            <div className={`lg:w-[18%] text-center lg:mt-10`}>
                                <span class="text-9xl font-bold text-blue-600 drop-shadow-[3px_3px_0px_rgba(0,0,0,1)]">4</span>
                                <h1 className="text-[20px] font-bold">Track and Analyze</h1>
                                <p className="text-[20px] leading-6 text-gray-600" style={{ fontWeight: 300 }}>Monitor scans,clicks,and user interactions in real time from your dashboard. Gain valuable insights into how your users engage with your app,allowing you to optimize your marketing strategies.</p>
                            </div>
    
                        </div>
    
                    </div>
                        <style>
                            {`
                        .animate-fade-in {
                            transform: scale(1);
                            opacity: 1;
                        }
                    `}
                        </style>
                    </section>
    
 
        </>
    )

   

}

export default SetupSteps;