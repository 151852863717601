import { useEffect } from "react";
import { url } from "../../urlConfig";
import { useNavigate } from "react-router-dom";

function Success() {
    // In SuccessPage component
    const navigate =useNavigate()
useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const sessionId = queryParams.get('session_id');
  
    if (sessionId) {
       const planId= localStorage.getItem("planId")
      const planName=  localStorage.getItem("planName")
       const userId= localStorage.getItem("userId")
      fetch(`${url}/purchase-plan`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
            planName:planName,
            planId:planId,
            userId:userId
         }),
      })
      .then(response => response.json())
      .then(data => {
        console.log('Payment verification result:', data.data);
        if(localStorage.getItem("lastUtl")){
          navigate(localStorage.getItem("lastUtl"))
        }else{
          navigate("/home")
        }
       
      });
    }
  }, []);
  
    return ( 
        <>
        </>
     );
}

export default Success;