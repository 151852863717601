import React, { useEffect, useState } from "react";
import { EyeIcon, EyeOffIcon } from "@heroicons/react/solid";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getAuth, signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { url } from "../../urlConfig";
import { Box, CircularProgress } from "@mui/material";

function Login() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [error, setError] = useState("");
    const [isProgress,setIsProgress]=useState(false)
    const navigate = useNavigate();
    const auth = getAuth();

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    // `const navigate = useNavigate();
    const location = useLocation();
  
    useEffect(() => {
      // Handle popstate event
      const handlePopState = (event) => {
        // Prevent going back to the login page
        if (location.pathname === '/login') {
          event.preventDefault();
          navigate('/login'); // Navigate back to home if they try to go back
        }
      };
  
      window.history.pushState(null, null, window.location.href); // Add a new state
      window.addEventListener('popstate', handlePopState);
  
      return () => {
        window.removeEventListener('popstate', handlePopState); // Clean up event listener
      };
    }, [location.pathname, navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsProgress(true)
        setError("");
        let formData ={email,password}
        fetch(`${url}/login`,{
            method:"POST",
            headers: {  "Content-Type": "application/json" },
            body:JSON.stringify(formData)
        }).then((res)=>{return res.json()})
        .then(response=>{
            if(response.status===true){
                localStorage.setItem("token",response.token)
                localStorage.setItem("isLogin",true)
                localStorage.setItem("userId",response.data._id)
                setIsProgress(false)
                 navigate("/home", { replace: true })
            }else{
                setError(response.message)
                setIsProgress(false)
            }
        })
    };

    
    

    const handleGoogleSignIn = async () => {
        const provider = new GoogleAuthProvider();
        try {
            const result = await signInWithPopup(auth, provider);
            const user = result.user;
            let email =user.email
            let formData ={email}
        fetch(`${url}/login-google`,{
            method:"POST",
            headers: {  "Content-Type": "application/json" },
            body:JSON.stringify(formData)
        }).then((res)=>{return res.json()})
        .then(response=>{
            console.log(response)
            if(response.status===true){
                
                localStorage.setItem("token",response.token)
                localStorage.setItem("isLogin",true)
                localStorage.setItem("userId",response.data._id)
                 navigate("/home")
            }else{
                setError(response.message)
            }
        })
        } catch (error) {
            setError("Google sign-in failed");
            console.error("Google sign-in error:", error.message);
        }
    };

    return (
        <div>
            <Link to={"/"}>
            <div className="font-bold flex ml-7 mt-5 text-[24px] text-blue-800 mr-48">
                        <img src="/Navbar1.png" alt="logo" style={{ height: "30px", width: "28px" }} className="mr-2 mt-1" />
                        <h2>SYNCQR.AI</h2>
                    </div>
                    </Link>

            <div className="flex flex-col justify-center items-center mt-10">
                <div className="bg-gray-50 lg:w-[40%] p-8 rounded-lg shadow-md">
                    <h2 className="text-3xl  text-center mb-8"style={{fontWeight:700}}>Login</h2>
                    <form onSubmit={handleSubmit}>
                        {error && <div className="text-red-600 text-sm mb-4">{error}</div>}
                        
                        <div className="mt-4"style={{fontWeight:400}}>
                            <label className="block text-sm font-medium text-gray-700">Email</label>
                            <input
                                type="email"
                                required
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Email"
                                className="mt-2 block w-full px-3 py-2 border-b-2 bg-white shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                            />
                        </div>

                        <div className="mt-4 relative"style={{fontWeight:400}}>
                            <label className="block text-sm font-medium text-gray-700">Password</label>
                            <input
                                type={passwordVisible ? "text" : "password"}
                                value={password}
                                required
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="Password"
                                className="mt-2 block w-full px-3 py-2 border-b-2 bg-white shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                            />
                            <button
                                type="button"
                                className="absolute inset-y-0 right-0 flex items-center px-3 mt-7 text-gray-500 focus:outline-none"
                                onClick={togglePasswordVisibility}
                            >
                                {passwordVisible ? (
                                    <EyeOffIcon className="w-5 h-5" />
                                ) : (
                                    <EyeIcon className="w-5 h-5" />
                                )}
                            </button>
                        </div>

                 

                        <div className="mt-6">
                            <button
                                type="submit"
                                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                            >
                                Continue
                            </button>
                        </div>
                        <div className="mt-4 text-center">
                            <a href="#" className="text-sm text-blue-800 font-semibold hover:underline" onClick={() => { navigate("/signup") }}>
                                Don't have an account? , SignUp Now!
                            </a>
                        </div>
                        <div className="mt-4 flex justify-center items-center">
                            <span className="text-gray-500">OR</span>
                        </div>

                        <div className="mt-4">
                            <button
                                type="button"
                                onClick={handleGoogleSignIn}
                                className="w-full flex justify-center text-blue-700 border-blue-500 items-center py-2 px-4 border rounded-md shadow-sm text-sm font-medium bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                            >
                                <svg
                                    className="w-5 h-5 mr-2"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="currentColor"
                                    viewBox="0 0 24 24"
                                >
                                    <path
                                        d="M21.35 11.1h-9.36v1.8h6.3c-.28 1.7-1.84 2.85-3.72 2.85-2.4 0-4.33-1.96-4.33-4.37s1.93-4.37 4.33-4.37c1.13 0 2.17.44 2.95 1.17l1.25-1.28C15.94 5.29 14.04 4.5 12 4.5c-3.86 0-7 3.14-7 7s3.14 7 7 7c3.65 0 6.67-2.67 7-6.15v-1.75h-1.65z"
                                    />
                                </svg>
                                Log in with Google
                            </button>
                        </div>

                      
                    </form>
                </div>
            </div>
            {isProgress===true &&
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 ">
            <Box sx={{ display: 'flex' }}>
      <CircularProgress />
    </Box>
            </div>
}
        </div>
        
    );
}

export default Login;
