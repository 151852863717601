import React from "react";
import YouTubeIcon from '@mui/icons-material/YouTube';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

function Footer() {
    return (
        <>
            <div className="bg-blue-800 " style={{marginTop:"100px"}}>
                <div className=" flex max-sm:flex-wrap font-bold text-2xl lg:justify-between max-sm:justify-center pt-10 text-white lg:ml-20">
                    <h2 >SYNCQR.AI</h2>
                    {/* <div className="space-x-8 lg:mr-20 font-medium">
                        <a href="/"><YouTubeIcon /></a>
                        <a href="/"><FacebookIcon /></a>
                        <a href="/"><TwitterIcon /></a>
                        <a href="/"><InstagramIcon /></a>
                        <a href="/"><LinkedInIcon /></a>
                    </div> */}
                </div>
                <div className="lg:w-[90%] lg:mt-10 lg:ml-20 max-sm:hidden " style={{ borderBottom: "1px solid white" }}></div>
                <div className="flex max-sm:flex-wrap lg:justify-between max-sm:justify-center max-sm:pt-5 max-sm:gap-5 lg:pt-20 text-white lg:ml-20 lg:pb-14 ">
                    <div className="hover:underline"style={{fontWeight:400}}>
                        <a href="#" >Copyright SYNCQR.AI @ 2024.All rights reserved.</a>
                    </div>
                    <div className="space-x-6 lg:mr-20 "style={{fontWeight:500}}>
                        <a className="hover:underline" href="#">Contact Us</a>
                        <a className="hover:underline" href="/privacy">Privacy Policy</a>
                        <a className="hover:underline" href="/termOfUse">Term of Service</a>
                    </div>
                </div>
                <div></div>
            </div>
        </>
    );
}

export default Footer;