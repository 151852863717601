import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function Banner() {
    const [contentVisible, setContentVisible] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        // Trigger the content visibility with a slight delay for animation
        setTimeout(() => {
            setContentVisible(true);
        }, 200); // Adjust the delay time as needed
    }, []);

    function handleClickGenerate() {
        if (localStorage.getItem("isLogin")) {
            navigate("/home");
        } else {
            navigate("/login");
        }
    }

    return (
        <>
            <section id="banner">
                <div className="bg-gradient-to-r from-blue-50 to-blue-300 lg:mt-20 max-sm:mt-[73px] flex max-sm:flex-wrap items-center justify-between">
                    
                    {/* Text Container with Animation */}
                    <div className={`max-sm:w-[100%] lg:w-[40%] lg:ml-16 mb-10 max-sm:px-2 lg:mt-20 transition-all duration-1000 ease-in-out transform ${contentVisible ? 'opacity-100 scale-100' : 'opacity-0 scale-90'}`}>
                        <h1 className="lg:text-[70px] max-sm:text-4xl text-black mb-4 leading-[66px]" style={{ fontWeight: 700 }}>
                            First impression <br /> is the last impression.
                        </h1>
                        <p className="lg:text-[25px] leading-6 mb-6 lg:mt-16" style={{ fontWeight: 400 }}>
                            Create single QR code and downloadable link for both Android and iOS apps. Seamlessly connect your users to the right app store with a
                            <span className="font-bold"> single scan</span> or a <span className="font-bold">click.</span>
                        </p>
                        <p className="text-[20px] font-medium">Join SYNCQR.AI today!</p>
                        <div className="max-sm:px-2 ">
                        <a
                            href="#pricing"
                            className="bg-gradient-to-r  text-xl from-blue-400 to-blue-600 text-white font-bold py-2 px-4 -ml-3 mt-4 rounded-full flex items-center justify-center gap-1 hover:shadow-md hover:shadow-blue-300 md:w-[20%] lg:w-[45%]"
                            onClick={handleClickGenerate}
                        >
                            Create QR Now
                        </a>
                        </div>
                       
                    </div>
                    
                    {/* Image Container with Animation */}
                    <div className={`mb-10 lg:mr-10 max-sm:px-2  transition-all duration-1000 ease-in-out transform ${contentVisible ? 'opacity-100 scale-100' : 'opacity-0 scale-90'}`}>
                        <img src="/mainBanner.png" alt="Banner" className="lg:w-[700px] lg:h-[269px] max-sm:w-[350px] max-sm:ml-5"  />
                    </div>
                   
                </div>
            </section>
        </>
    );
}

export default Banner;
