import React, { useEffect } from "react";
import Navbar from "../../Components/Navbar2/Navbar1";
import Banner from "../../Components/Banner";
import Features from "../../Components/Features";
// import MainContant from "../../Components/MainContant";
import Footer from "../../Components/Footer";
import { useNavigate } from "react-router-dom";
import SetupSteps from "../../Components/MainContant/steps";
import Brands from "../../Components/MainContant/brands";
import Testimonials from "../../Components/MainContant/testimonials";
import GetStarted from "../../Components/MainContant/getStarted";
function HomePage() {
    const navigate=useNavigate()
    useEffect(()=>{
        if(localStorage.getItem("isLogin")){
            navigate("/home")
        }
    },[])
    return ( 
        <>
        <Navbar/>
        <Banner/>
        <Features/>
        <SetupSteps/>
        <Brands/>
        <Testimonials/>
        <GetStarted/>
        {/* <MainContant/> */}
        <Footer/>
        </>
     );
}

export default HomePage;