import React, { useEffect, useState } from "react";
import { useRef } from 'react';
import { useNavigate } from "react-router-dom";

function Testimonials() {
    let scrollPosition = 0;
    const headingRef = useRef(null);
    const anchorRef = useRef(null);
    const stepRefs = useRef([]);


    const handleScroll = (direction) => {
        const container = document.getElementById('scroll-container');
        const itemWidth = container.firstChild.offsetWidth + 32; // Add space between items
        const visibleItems = 2;

        const maxScrollPosition = container.childElementCount - visibleItems;

        if (direction === 'prev') {
            scrollPosition = Math.max(scrollPosition - 1, 0);
        } else if (direction === 'next') {
            scrollPosition = Math.min(scrollPosition + 1, maxScrollPosition);
        }

        container.style.transform = `translateX(-${scrollPosition * itemWidth}px)`;
    };


    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add("animate-fade-in");
                    }
                });
            },
            { threshold: 0.2 }
        );

        // Observe heading and anchor elements
        if (headingRef.current) observer.observe(headingRef.current);
        if (anchorRef.current) observer.observe(anchorRef.current);

        // Observe each step element
        stepRefs.current.forEach((ref) => {
            if (ref) observer.observe(ref);
        });

        // Cleanup observer on component unmount
        return () => {
            if (headingRef.current) observer.unobserve(headingRef.current);
            if (anchorRef.current) observer.unobserve(anchorRef.current);
            stepRefs.current.forEach((ref) => {
                if (ref) observer.unobserve(ref);
            });
        };
    }, []);


    return (
        <>
            <div>
                <section id="testimonials">
                    <div className="bg-gradient-to-r from-blue-50 to-blue-200 max-sm:px-2  lg:p-12 lg:mt-20 max-sm:mt-14 w-[100%]">
                        <div 
                        ref={headingRef}
                        className="opacity-0 transform scale-75 transition-all duration-1000 "
                        >
                            <div className="flex items-center justify-center py-4">
                                <a

                                    className="bg-blue-50 rounded-3xl py-1 px-2 text-[20px] text-blue-800 font-medium    " style={{ fontWeight: 500 }}>TESTIMONIALS</a>
                            </div>
                            <div>
                                <h2

                                    className="text-[50px] font-sans font-bold text-center mt-6 max-sm:text-[30px] max-sm:leading-10  " style={{ fontWeight: 700 }}>What Our Partners Say</h2>
                            </div>

                            <div className="lg:relative flex justify-center items-center mt-10  w-[100%]  ">
                                {/* Left Arrow */}
                                <button
                                    className="absolute lg:left-10  max-sm:hidden transform -translate-y-1/2 bg-gray-50 p-2 rounded-full cursor-pointer hover:bg-gray-300"
                                    onClick={() => handleScroll('prev')}
                                >
                                    &#9664;
                                </button>


                                <div className="lg:overflow-hidden max-sm:scrollbar-hide lg:w-[85%] max-sm:w-[100%] mb-20 ">
                                    <div
                                        className="flex max-sm:overscroll-x-conatin max-sm:overflow-x-scroll max-sm:gap-5 max-sm:scrollbar-hide  lg:space-x-5 lg:transition-transform duration-300"
                                        id="scroll-container"
                                    >
                                        <div className="lg:w-[600px] lg:min-w-[500px] lg:h-[250px] max-sm:flex-none max-sm:w-[100%] bg-white border border-gray-200 p-8 text-center cursor-pointer hover:shadow-lg  lg:relative ">
                                            <div className="flex justify-center">
                                                <img src="/opdqLogo.png" style={{ height: "30px" }} />
                                            </div>
                                            <p className=" mt-9 text-[18px]" style={{ fontWeight: 400 }}>
                                                After implementing the QR code, we noticed a significant increase in app installs. It made the process much easier for users, allowing them to scan and install the app seamlessly, regardless of their device.
                                            </p>
                                            {/* <div className="mt-8">
                                            <img className="h-12 mx-auto rounded-full" src="profile.png" alt="Author" />
                                            <h1 className="font-bold text-[24px] mt-1" style={{ fontWeight: 700 }}>Author Name</h1>
                                            <h2 className=" text-[18px] -mt-2" style={{ fontWeight: 400 }}>Role</h2>
                                        </div> */}
                                        </div>
                                        <div className="lg:w-[600px] lg:min-w-[500px] lg:h-[250px] max-sm:flex-none max-sm:w-[100%] bg-white border border-gray-200 p-8 text-center cursor-pointer hover:shadow-lg  lg:relative ">
                                            <div className="flex justify-center">
                                                <img src="/rentahLogo.png" style={{ height: "40px" }} />
                                            </div>
                                            <p className=" mt-6 text-[18px]" style={{ fontWeight: 400 }}>
                                                Marketing team is pleased with the addition of QR codes, as they simplify the customer’s decision to download and use our app, making the process more seamless and accessible.
                                            </p>
                                            {/* <div className="mt-8">
                                            <img className="h-12 mx-auto rounded-full" src="profile.png" alt="Author" />
                                            <h1 className="font-bold text-[24px] mt-1" style={{ fontWeight: 700 }}>Author Name</h1>
                                            <h2 className=" text-[18px] -mt-2" style={{ fontWeight: 400 }}>Role</h2>
                                        </div> */}
                                        </div>


                                    </div>
                                </div>


                                <button
                                    className="absolute right-12 max-sm:hidden  transform -translate-y-1/2 bg-gray-200 p-2 rounded-full cursor-pointer hover:bg-gray-300"
                                    onClick={() => handleScroll('next')}
                                >
                                    &#9654;
                                </button>
                            </div>
                        </div>





                    </div>
                </section>

            </div>
        </>
    )



}

export default Testimonials;