import React from "react";

function OtpModal({ isOpen, onClose,setIsOpen, onResend,handleVerifyOtp,formData,setFormData,handleSubmit }) {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 ">
            <div className="bg-white p-8 rounded-3xl lg:w-[40%] max-sm:w-[90%] h-[280px]">
                <div className="text-center -mt-10">
                    <button className="text-[25px] ml-96 pl-40 mt-2" onClick={()=>{setIsOpen(false)}}>X</button>
                </div>
                <h2 className="text-2xl  text-center mb-8"style={{fontWeight:700}}>Enter OTP received on email</h2>
                <input
                    type="text"
                    style={{fontWeight:400}}
                    placeholder="Enter Here"
                    value={formData.otp}
                    onChange={(e)=>{setFormData({
                        ...formData,
                        otp:e.target.value
                    });}}
                    className="w-full px-4 py-2 border-b-2 outline-none focus:border-blue-400 bg-gray-50 mb-6"
                />
                <button className="w-full bg-blue-800 text-white py-2 mb-3" onClick={handleVerifyOtp}style={{fontWeight:400}}>Continue</button>
                <button
                style={{fontWeight:400,marginBottom:"5px"}}
                    className="w-full text-blue-800 underline "
                    onClick={handleSubmit}
                >
                    Resend OTP
                </button>
            </div>
        </div>
    );
}

export default OtpModal;
