import React, { useEffect, useState } from "react";
import { Tabs, Tab, Box } from "@mui/material";
import Home from "../../Components/Home/home";
import NavbarCreatePage from "../../Components/Navbar2/Navbar2";
import ContactSupport from "../../Components/Home/contactSupport";

import SubscriptionPage from "../../Components/Home/subscription";
import { useNavigate, useParams } from "react-router-dom";
import { useLocation } from 'react-router-dom';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};
function CreateHome() {

    const navigate = useNavigate();
    const location = useLocation();
  
    useEffect(() => {
      // Handle popstate event
      const handlePopState = (event) => {
        // Prevent going back to the login page
        if (location.pathname === '/home') {
          event.preventDefault();
          navigate('/home'); // Navigate back to home if they try to go back
        }
      };
  
      window.history.pushState(null, null, window.location.href); // Add a new state
      window.addEventListener('popstate', handlePopState);
  
      return () => {
        window.removeEventListener('popstate', handlePopState); // Clean up event listener
      };

    
    }, [location.pathname, navigate]);
    const query = useQuery();

    // Example: Get a query parameter named "id"
    const tab = query.get('tab');
    const [currentTab, setCurrentTab] = useState(null);

    const handleChange = (event, newValue) => {
        setCurrentTab(newValue);
    };

useEffect(()=>{
    if(!localStorage.getItem("isLogin")){
        setCurrentTab(2)
    }else{
      setCurrentTab(Number(tab))
    }

},[])

    return (
        <>
            <div className="w-full">
                <NavbarCreatePage currentTab={currentTab} setCurrentTab={setCurrentTab} />
                
                <Box >
                    {currentTab === 0 && <Home />}
                    {currentTab === 1 && <ContactSupport/>}
                    {currentTab === 2 && <SubscriptionPage/>}
                </Box>
            </div>
        </>
    );
}

export default CreateHome;
