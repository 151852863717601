function FeatureCards({card}) {
    return (
        <>
            <div className="lg:w-[326px] lg:h-[346px]  rounded-3xl border border-gray-300 p-4 text-start cursor-pointer hover:shadow-md hover:bg-slate-30  lg:flex-none items-center relative" style={{ borderRadius: "50px" }}>

                <div className=" ">
                    <div className="w-full flex justify-center mt-5">
                    <img src={card.image} style={{height:"101px",width:"101px"}}/>
                    </div>
                    
                    <h2 className=" font-bold mt-8  text-[20px]">{card.title}</h2>
                    <p className="text-[20px]  text-gray-700 mt-2  leading-6" style={{fontWeight:300}}>{card.description}</p>
                </div>
            </div>

        </>
    );
}

export default FeatureCards;