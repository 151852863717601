import React from 'react';
import Navbar from '../../Components/Navbar2/Navbar1';
import { NavLink } from 'react-router-dom';

const TermsOfUse = () => {
  return (
    <>
    <Navbar/>
    <div className="lg:px-60 max-sm:px-5 mt-40 font-normal text-lg ">
    <NavLink to={"/"}>
                <button className="mb-10 hover:underline text-lg hover:text-blue-700">← Back To Home</button>
                </NavLink>
      <h1 className="text-3xl font-bold mb-6">Terms of Use</h1>

      <section className="mb-8">
        <p>
          Thank you for using <span className="font-semibold">syncqr.ai</span>! These terms of service (Terms) cover your use and access of our website and related software (our Services).
        </p>
        <p>
          These Services are provided by <span className="font-semibold">Syncqr.ai</span>.
        </p>
        <p>
          By using our Services you agree to these Terms and to review our <span className="font-semibold">Privacy Policy</span>. If you do not agree to these Terms or our Privacy Policy, you should not use our Services.
        </p>
      </section>

      {/* Software */}
      <section className="mb-8">
        <div className="flex items-start">
          <svg className="h-3 w-3 mr-2 mt-1" viewBox="0 0 20 20" fill="currentColor">
            <circle cx="10" cy="10" r="5" />
          </svg>
          <h2 className="text-xl font-bold mb-4">Software</h2>
        </div>
        <p>
          Our Services may include web-based or downloadable software, which may update automatically on your device to newer versions. We grant you a worldwide, non-exclusive and non-transferable license to use the software solely to use the Services. Components of the software may be offered under an open source license; in this case, we will make that license available to you. Provisions of the open source license may expressly override some of these Terms.
        </p>
      </section>

      {/* Changes to Services */}
      <section className="mb-8">
        <div className="flex items-start">
          <svg className="h-3 w-3 mr-2 mt-1" viewBox="0 0 20 20" fill="currentColor">
            <circle cx="10" cy="10" r="5" />
          </svg>
          <h2 className="text-xl font-bold mb-4">Changes to Services</h2>
        </div>
        <p>
          We may change the features of our Services, withdraw or add new features from time to time.
        </p>
      </section>

      {/* Pricing */}
      <section className="mb-8">
        <div className="flex items-start">
          <svg className="h-3 w-3 mr-2 mt-1" viewBox="0 0 20 20" fill="currentColor">
            <circle cx="10" cy="10" r="5" />
          </svg>
          <h2 className="text-xl font-bold mb-4">Pricing</h2>
        </div>
        <p>
          Users can upgrade their account at a price of <span className="font-semibold">99$/year</span> flat fees that accommodate the creation of 5 codes. More facilities can be upgraded by requesting on partnerships@syncqr.ai.
        </p>
      </section>

      {/* Limitation of Liability */}
      <section className="mb-8">
        <div className="flex items-start">
          <svg className="h-3 w-3 mr-2 mt-1" viewBox="0 0 20 20" fill="currentColor">
            <circle cx="10" cy="10" r="5" />
          </svg>
          <h2 className="text-xl font-bold mb-4">Limitation of Liability</h2>
        </div>
        <p>
          To the fullest extent permitted by law, in no event shall we be liable for any damages or loss of data, business, profits, computer hardware or software. In no event shall our liability to you exceed the greater of 99 USD or the amounts paid by you to us for the past 12 months.
        </p>
      </section>

      {/* Warranties */}
      <section className="mb-8">
        <div className="flex items-start">
          <svg className="h-3 w-3 mr-2 mt-1" viewBox="0 0 20 20" fill="currentColor">
            <circle cx="10" cy="10" r="5" />
          </svg>
          <h2 className="text-xl font-bold mb-4">Warranties</h2>
        </div>
        <p>
          We strive to provide good Services and hope you enjoy using them. But there are things we cannot guarantee. We provide our Services <span className="font-semibold">"As is"</span>. To the fullest extent permitted by law, we make no warranties either implied or expressed about the Services. We do not make any promise about the specific functions of the services, their reliability or availability.
        </p>
      </section>

      {/* Changes to Terms */}
      <section className="mb-8">
        <div className="flex items-start">
          <svg className="h-3 w-3 mr-2 mt-1" viewBox="0 0 20 20" fill="currentColor">
            <circle cx="10" cy="10" r="5" />
          </svg>
          <h2 className="text-xl font-bold mb-4">Changes to Terms</h2>
        </div>
        <p>
          We reserve the right to change these Terms at any time. We will always post the most current version on our website. By continuing to use the Services after the changes become effective, you agree to the revised Terms.
        </p>
      </section>
    </div>
    </>
    
  );
};

export default TermsOfUse;
