import React, { useEffect, useState } from "react";
import { EyeIcon, EyeOffIcon } from "@heroicons/react/solid";
import { Link, useNavigate } from "react-router-dom";
import { getAuth, signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import app from "../../firebaseConfig";
import { url } from "../../urlConfig";
import OtpModal from "../../Components/OtpModal";
import { Box, CircularProgress } from "@mui/material";
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
function SignUp() {
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        agreeToTerms: false,
        otp:"",
        signupType:null
    });
    
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    // const [otp, setOtp] = useState("");
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const [isProgress,setIsProgress]=useState(false)
    const auth = getAuth(app);
    const provider = new GoogleAuthProvider();

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const validateForm = () => {
        const errors = {};
        if (!formData.firstName) errors.firstName = "First Name is required";
        if (!formData.email) errors.email = "Email is required";
        if (!formData.password) errors.password = "Password is required";
        if (!formData.agreeToTerms) errors.agreeToTerms = "You must agree to the terms and conditions.";
        return errors;
    };

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === "checkbox" ? checked : value,
        });
    };

    const handleSubmit = (e) => {
        // setIsModalOpen(false)
        e.preventDefault();

        const validationErrors = validateForm();
        setErrors(validationErrors);
         setIsProgress(true)
        // if (Object.keys(validationErrors).length === 0) {
        //     createUserWithEmailAndPassword(auth, formData.email, formData.password)
        //         .then((userCredential) => {
        //             const user = userCredential.user;
        //             sendEmailVerification(user)
        //                 .then(() => {
        //                     setIsModalOpen(true);  // Open OTP modal
        //                 })
        //                 .catch((error) => {
        //                     setErrors({ form: error.message });
        //                 });
        //         })
        //         .catch((error) => {
        //             setErrors({ form: error.message });
        //         });
        // }
       let email=formData.email
       let formData2={email}
        fetch(`${url}/send-signup-otp`,{
            method:"POST",
            headers: {  "Content-Type": "application/json" },
            body:JSON.stringify(formData2)
        }).then((res)=>{return res.json()})
        .then(response=>{
            console.log(response)
            if(response.status===true){
                toast.success("OTP sent successfully")
                setIsProgress(false)
                setIsModalOpen(true);  // Open OTP modal
            }else{
                setErrors({form:response.message})
                setIsProgress(false)
            }
        })
    };

    const handleGoogleSignIn = async () => {
        try {
            const result = await signInWithPopup(auth, provider);
            setFormData({
                ...formData,
                firstName: result._tokenResponse.firstName,
                lastName: result._tokenResponse.lastName,
                email: result._tokenResponse.email,
                password: "",
                signupType: 1
            });
        } catch (error) {
            console.error("Google Sign-In Error:", error);
            setErrors({ form: "Failed to sign in with Google." });
        }
    };

    useEffect(() => {
        if (formData.signupType === 1) {
            // Only run the API call when signupType is updated for Google Sign-In
            fetch(`${url}/sign-up`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(formData)
            })
            .then(res => res.json())
            .then(response => {
                if (response.status === true) {
                    localStorage.setItem("token", response.token);
                    localStorage.setItem("isLogin", true);
                    localStorage.setItem("userId", response.data._id);
                    navigate("/?tab=0");
                } else {
                    setErrors({ form: response.message });
                }
            })
            .catch(error => {
                setErrors({ form: "API Error: " + error.message });
            });
        }
    }, [formData.signupType]);

    const handleVerifyOtp = () => {
        // auth.currentUser.reload().then(() => {
            // if (auth.currentUser.emailVerified) {
                // Store user data and navigate
                // formData.otp=otp
                setIsProgress(true)
                fetch(`${url}/sign-up`, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(formData)
                })
                .then(res => res.json())
                .then(response => {
                    console.log(response)
                    if (response.status === true) {
                        localStorage.setItem("token", response.token);
                        localStorage.setItem("isLogin", true);
                        localStorage.setItem("userId", response.data._id);
                        setIsProgress(false)
                        navigate("/?tab=0");
                    }else{
                        setErrors({form:response.message})
                        setIsProgress(false)
                    }
                });
            // } else {
            //     setErrors({ form: "OTP verification failed. Please try again." });
            // }
        // });
    }; 
    
    

    return (
        <>
        <ToastContainer/>
            <div>
            <Link to={"/"}>
            <div className="font-bold flex ml-7 mt-5 text-[24px] text-blue-800 mr-48">
                        <img src="/Navbar1.png" alt="logo" style={{ height: "30px", width: "28px" }} className="mr-2 mt-1" />
                        <h2>SYNCQR.AI</h2>
                    </div>
                    </Link>

                <div className="flex flex-col justify-center items-center mt-2">
                    <div className="bg-gray-50 lg:w-[40%] rounded-md pl-10 pr-10 pt-5 pb-2 shadow-lg">
                        <h2 className="text-3xl  text-center mb-8"style={{fontWeight:700}}>Create your account</h2>
                        {errors.form && <p className="text-red-500 text-center">{errors.form}</p>}
                        {/* <form > */}
                            <div className="grid grid-cols-2 gap-4">
                                <div style={{fontWeight:500}}>
                                    <label className="block text-sm  text-gray-700">First Name <span className="text-red-500 text-xl">*</span></label>
                                    <input
                                        type="text"
                                        name="firstName"
                                        value={formData.firstName}
                                        onChange={handleInputChange}
                                        placeholder="First Name"
                                        className={`mt-2 block w-full px-3 py-2 border-b-2 bg-white shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 ${errors.firstName ? "border-red-500" : ""}`}
                                    />
                                    {errors.firstName && <p className="text-red-500 text-sm">{errors.firstName}</p>}
                                </div>
                                <div style={{fontWeight:400}}>
                                    <label className="block mt-2 text-sm font-medium text-gray-700">Last Name</label>
                                    <input
                                        type="text"
                                        name="lastName"
                                        value={formData.lastName}
                                        onChange={handleInputChange}
                                        placeholder="Last Name"
                                        className={`mt-2 block w-full px-3 py-2 border-b-2 bg-white shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 ${errors.lastName ? "border-red-500" : ""}`}
                                    />
                                    {errors.lastName && <p className="text-red-500 text-sm">{errors.lastName}</p>}
                                </div>
                            </div>

                            <div className="mt-4"style={{fontWeight:400}}>
                                <label className="block text-sm font-medium text-gray-700">Email<span className="text-red-500 text-xl">*</span></label>
                                <input
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                    placeholder="Email"
                                    className={`mt-2 block w-full px-3 py-2 border-b-2 bg-white shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 ${errors.email ? "border-red-500" : ""}`}
                                />
                                {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
                            </div>

                            <div className="mt-4 relative"style={{fontWeight:400}}>
                                <label className="block text-sm font-medium text-gray-700">Password<span className="text-red-500 text-xl">*</span></label>
                                <input
                                    type={passwordVisible ? "text" : "password"}
                                    name="password"
                                    value={formData.password}
                                    onChange={handleInputChange}
                                    placeholder="Password"
                                    className={`mt-2 block w-full px-3 py-2 border-b-2 bg-white shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 ${errors.password ? "border-red-500" : ""}`}
                                />
                                <button
                                    type="button"
                                    className="absolute inset-y-0 right-0 flex items-center px-3 mt-7 text-gray-500 focus:outline-none"
                                    onClick={togglePasswordVisibility}
                                >
                                    {passwordVisible ? (
                                        <EyeOffIcon className="w-5 h-5" />
                                    ) : (
                                        <EyeIcon className="w-5 h-5" />
                                    )}
                                </button>
                                {errors.password && <p className="text-red-500 text-sm">{errors.password}</p>}
                            </div>

                            <div className="mt-8 flex items-center">
                                <input
                                    type="checkbox"
                                    name="agreeToTerms"
                                    checked={formData.agreeToTerms}
                                    onChange={handleInputChange}
                                    className={`h-4 w-4 text-blue-600 rounded ${errors.agreeToTerms ? "border-red-500" : ""}`}
                                />
                                <label className="ml-2 block text-[14px] text-gray-900"style={{fontWeight:400}}>
                                    By creating an account, you agree to the privacy policy and terms.
                                </label>
                            </div>
                            {errors.agreeToTerms && <p className="text-red-500 text-sm">{errors.agreeToTerms}</p>}

                            <div className="mt-6">
                                <button
                                    // type="submit"
                                    onClick={handleSubmit}
                                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                >
                                    Continue
                                </button>
                            </div>
                            <div className="mt-4 text-center">
                            <a href="#" className="text-sm text-blue-800 font-semibold hover:underline" onClick={() => { navigate("/login") }}>
                                Already have an account?
                            </a>
                        </div>
                            <div className="mt-4 flex justify-center items-center">
                                <span className="text-gray-500">OR</span>
                            </div>

                            <div className="mt-4">
                                <button
                                    type="button"
                                    onClick={handleGoogleSignIn}
                                    className="w-full flex justify-center text-blue-700 border-blue-500 items-center py-2 px-4 border-2 rounded-md shadow-sm text-sm font-medium bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                >
                                    Continue with Google
                                </button>
                            </div>
                        {/* </form> */}
                    </div>
                </div>
            </div>
            {isProgress===true &&
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 ">
            <Box sx={{ display: 'flex' }}>
      <CircularProgress />
    </Box>
            </div>
}
            {isModalOpen && (
                <OtpModal
                    isOpen={isModalOpen}
                    setIsOpen={setIsModalOpen}
                    // otp={formData.otp}
                    handleSubmit={handleSubmit}
                    formData={formData}
                    setFormData={setFormData}
                    handleVerifyOtp={handleVerifyOtp}
                />
            )}
        </>
    );
}

export default SignUp;
