import React, { useEffect, useState } from "react";
import LogoutIcon from "@mui/icons-material/Logout";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import QrCode from "../../Components/QrCode";
import { useParams } from "react-router-dom";
import { url } from "../../urlConfig";
import { toPng, toJpeg } from "html-to-image"; // For converting QR code to image
import { useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { QRCode } from "react-qrcode-logo";

// import { toPng } from 'html-to-image';
function Designing() {
  const qrCodeRef = useRef();
  const navigate = useNavigate();
  const [bgColor, setBgColor] = useState("#FFFFFF");
  const [qrColor, setQrColor] = useState("#000000");
  const [logoBgColor, setLogoBgColor] = useState("#FFFFFF");
  const [style, setStyle] = useState("squares");
  const [corners, setCorners] = useState("squares");
  const [logo, setLogo] = useState(null);
  const [error, setError] = useState("");
  const [qr, setQr] = useState("");
  const [qrLink, setQrLink] = useState("");
  const [title, setTitle] = useState("");
  const { qrId } = useParams();
  const [format, setFormat] = useState("jpeg");
  const [isSaved, setIsSaved] = useState(true);

  useEffect(() => {
    setIsSaved(false);
  }, [bgColor, qrColor, logoBgColor, style, logo, qr]);

  useEffect(() => {
    fetch(`${url}/get-qr-by-id/${qrId}`, {
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        return res.json();
      })
      .then((response) => {
        if (response.status === true) {
          setQr(response.data);
          setBgColor(response.data.bgColor);
          setQrColor(response.data.color);
          setLogoBgColor(response.data.logoBg);
          setStyle(response.data.qrStyle);
          setLogo(response.data.logo);
          setQrLink(response.data.qrLink);
          setTitle(response.data.appName);
        } else {
        }
      });
  }, [qrId]);

  const handleLogoUpload = (e) => {
    const formData = new FormData();

    formData.append("image", e.target.files[0]);

    // console.log(fileList)
    fetch(`${url}/upload-single-image`, {
      method: "POST",
      //  headers :{"Content-Type":"application/json" },
      body: formData,
    })
      .then((res) => {
        return res.json();
      })
      .then((response) => {
        console.log(response);
        setLogo(response.imageUrl);
      });
  };

  const handleDeleteLogo = () => {
    setLogo(null);
  };

  const handleDownload = async () => {
    const node = qrCodeRef.current;
    const formData = { bgColor, qrColor, logo, logoBgColor, style };

    if (isSaved === true) {
        let width=300
        let height=300
        toPng(node, { cacheBust: false, width, height })
        .then((dataUrl) => {
          const link = document.createElement('a');
          link.download = 'qr-code.png';
          link.href = dataUrl;
          link.click();
        })
       
    } else {
      fetch(`${url}/update-qr/${qrId}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.status === true) {
            setIsSaved(true);
          }
        });
    }
  };

  function handleColorInputChange(e, setColor) {
    setBgColor(e.target.value);
    const hex = e.target.value;
    const isValidHex = /^#([0-9A-F]{3}){1,2}$/i.test(hex);
    if (isValidHex) {
      setColor(hex);
    }
  }

  function handleLogOut() {
    const res = window.confirm("Are you sure to logout");
    if (res === true) {
      localStorage.removeItem("token");
      localStorage.removeItem("isLogin");
      localStorage.removeItem("userId");
      navigate("/login");
    }
  }

  const handleQRColorInputChange = (e, setColor) => {
    setQrColor(e.target.value);
    const hex = e.target.value;
    const isValidHex = /^#([0-9A-F]{3}){1,2}$/i.test(hex);
    if (isValidHex) {
      setColor(hex);
    }
  };
  const handleLogoBGColorInputChange = (e, setColor) => {
    setLogoBgColor(e.target.value);
    const hex = e.target.value;
    const isValidHex = /^#([0-9A-F]{3}){1,2}$/i.test(hex);
    if (isValidHex) {
      setColor(hex);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100">
      {/* Navbar */}
      <nav className="fixed top-0 left-0 right-0  z-50 bg-white py-4 shadow-md">
        <div className="container mx-auto flex justify-between items-center px-8">
          <div className="text-3xl " style={{ fontWeight: 700 }}>
            SYNCQR.AI
          </div>
          <a
            onClick={handleLogOut}
            className="text-[16px] cursor-pointer  hover:text-blue-500"
            style={{ fontWeight: 500 }}
          >
            Logout
            <LogoutIcon
              className="ml-1 mb-1 cursor-pointer "
              style={{ fontSize: "25px" }}
            />
          </a>
        </div>
      </nav>

      <div className="mt-16 lg:grid lg:grid-cols-2 gap-10 px-8 max-sm:hidden">
        <div className="lg:p-8  ">
          <h2
            className="text-[40px] mb-6 max-sm:mt-10"
            style={{ fontWeight: 700 }}
          >
            Designing
          </h2>

          {/* Background Color */}
          <div className="mb-6">
            <label
              className="block text-[20px] font-medium mb-2"
              style={{ fontWeight: 500 }}
            >
              Select your code background color
            </label>
            <div className="flex space-x-2 mb-2">
              {[
                "#FFFFFF",
                "#000000",
                "#FF0000",
                "#00FF00",
                "#34ebd2",
                "#FFFF00",
              ].map((color) => (
                <div
                  key={color}
                  className={`w-14 h-14 border-2 cursor-pointer ${
                    color === bgColor ? "border-black" : "border-gray-100"
                  }`}
                  style={{ backgroundColor: color }}
                  onClick={() => setBgColor(color)}
                />
              ))}
            </div>
            <div className="flex items-center">
              <div className="flex border border-black bg-white w-[58%] text-center items-center">
                <div
                  className="w-12 ml-2 mb-2 border  h-10 rounded-sm mt-2 "
                  style={{ backgroundColor: bgColor }}
                ></div>
                <input
                  type="text"
                  value={bgColor}
                  placeholder="Enter a color value"
                  onChange={(e) => handleColorInputChange(e, setBgColor)}
                  className="w-full text-[20px] focus:outline-none font-semibold text-gray-400 p-3"
                  style={{ fontWeight: 500 }}
                />
              </div>
            </div>
          </div>
          <hr className="w-[58%] border-gray-300 mb-3" />

          {/* QR Code Color */}
          <div className="mb-6">
            <label
              className="block text-[20px] font-medium mb-2"
              style={{ fontWeight: 500 }}
            >
              Select your QR code color
            </label>
            <div className="flex space-x-2 mb-2">
              {[
                "#FFFFFF",
                "#000000",
                "#FF0000",
                "#00FF00",
                "#34ebd2",
                "#FFFF00",
              ].map((color) => (
                <div
                  key={color}
                  className={`w-14 h-14 border-2 cursor-pointer ${
                    color === qrColor ? "border-black" : "border-gray-100"
                  }`}
                  style={{ backgroundColor: color }}
                  onClick={() => setQrColor(color)}
                />
              ))}
            </div>
            <div className="flex items-center">
              <div className="flex border border-black bg-white w-[58%] text-center items-center">
                <div
                  className="w-12 ml-2 mb-2 border  h-10 rounded-sm mt-2   "
                  style={{ backgroundColor: qrColor }}
                ></div>

                <input
                  type="text"
                  value={qrColor}
                  placeholder="Enter a color value"
                  onChange={(e) => handleQRColorInputChange(e, setQrColor)}
                  className="w-full text-[20px] focus:outline-none font-semibold text-gray-400 p-3"
                  style={{ fontWeight: 500 }}
                />
              </div>
            </div>
          </div>
          <hr className="w-[58%] border-gray-300 mb-3" />
          {/* QR Code Style */}
          <div className="mb-6">
            <label
              className="block text-[20px] font-medium mb-2"
              style={{ fontWeight: 500 }}
            >
              QR code style
            </label>
            <div className="inline-flex border border-black overflow-hidden">
              <label
                className={`flex items-center px-4 py-2 border-r border-black  cursor-pointer ${
                  style === "squares" ? "bg-blue-50" : "bg-white"
                }`}
                onClick={() => setStyle("squares")}
                style={{ fontWeight: 500 }}
              >
                <input
                  type="radio"
                  name="style"
                  value="squares"
                  checked={style === "squares"}
                  onChange={(e) => setStyle(e.target.value)}
                  className="hidden"
                />
                <div
                  className={`w-4 h-4 mr-2 border-2 rounded-full flex justify-center items-center ${
                    style === "squares" ? "border-blue-600" : "border-gray-400"
                  }`}
                >
                  {style === "squares" && (
                    <div className="w-2 h-2 bg-blue-800 rounded-full"></div>
                  )}
                </div>
                <span className="text-sm">Squares</span>
              </label>

              <label
                className={`flex items-center px-4 py-2 cursor-pointer ${
                  style === "dots" ? "bg-blue-50" : "bg-white"
                }`}
                onClick={() => setStyle("dots")}
                style={{ fontWeight: 500 }}
              >
                <input
                  type="radio"
                  name="style"
                  value="dots"
                  checked={style === "dots"}
                  onChange={(e) => setStyle(e.target.value)}
                  className="hidden"
                />
                <div
                  className={`w-4 h-4 mr-2 border-2 rounded-full flex justify-center items-center ${
                    style === "dots" ? "border-blue-600" : "border-gray-400"
                  }`}
                >
                  {style === "dots" && (
                    <div className="w-2 h-2 bg-blue-800 rounded-full"></div>
                  )}
                </div>
                <span className="text-sm">Dots</span>
              </label>
            </div>
          </div>
          <hr className="w-[58%] border-gray-300 mb-3" />
          
          <div className="mb-4">
            <label
              className="block text-[20px] font-medium mb-4"
              style={{ fontWeight: 500 }}
            >
              Add your brand logo in center
            </label>
            <div className="w-14 h-14 border-2 border-gray-500 flex items-center justify-center relative cursor-pointer">
              <input
                type="file"
                accept="image/*"
                onChange={handleLogoUpload}
                className="absolute inset-0 opacity-0 cursor-pointer"
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1"
                stroke="currentColor"
                className="w-8 h-8  "
              >
                <CloudUploadIcon className="text-black" fontSize="large" />
              </svg>
            </div>

            {logo && (
              <div className=" mt-4  ">
                <img src={logo} alt="Logo" className="w-14 h-14 rounded-md " />
                <button
                  onClick={handleDeleteLogo}
                  className="relative -top-16 -right-10  bg-red-600 text-white w-5 h-5 rounded-full flex items-center justify-center"
                  style={{ fontSize: "14px" }}
                >
                  &times;
                </button>
              </div>
            )}
          </div>

          
        </div>

        {/* Preview Section */}
        <div className="lg:p-8 lg:fixed lg:top-20 lg:right-10  ">
          <h2
            className="text-[40px]  mb-14 text-center "
            style={{ fontWeight: 700 }}
          >
            Preview
          </h2>
          <div className="w-[50%] h-[300px] border lg:ml-40 max-sm:ml-20 border-gray-200 bg-white rounded-lg flex justify-center items-center mb-6 ">
            {/* <QrCode ref={qrCodeRef} size={250} link={qrLink} color={bgColor} qrColor={qrColor} styles={style} corners={"h"} logo={logo} logoBg={logoBgColor}/> */}
            <div
              style={{
                textAlign: "center",
                padding: "20px",
                backgroundColor: "#ffffff",
              }}
            >
              <div ref={qrCodeRef} >
                <QRCode
                  value={qrLink}
                  size={250}
                  bgColor={bgColor}
                  fgColor={qrColor}
                  level="Q"
                  includeMargin={true}
                  qrStyle={style}
                  logoImage={logo}
                  logoWidth={60}
                  logoHeight={60}
                  logoOpacity={0.8}
                  eyeRadius={[
                    {
                      outer: [10, 10, 0, 0],
                      inner: [0, 0, 10, 10],
                    },
                    {
                      outer: [10, 0, 10, 0],
                      inner: [10, 0, 0, 10],
                    },
                    {
                      outer: [0, 10, 0, 10],
                      inner: [10, 10, 10, 0],
                    },
                  ]}
                  removeQrCodeBehindLogo={true}
                  logoBackgroundColor="transparent"
                />
              </div>
            </div>
          </div>
          {isSaved ? (
            <button
              onClick={handleDownload}
              className="w-[55%] text-[20px] max-sm:ml-[70px] lg:ml-36 mt-5 bg-blue-800 text-white py-2 rounded-lg hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-blue-500"
              style={{ fontWeight: 600 }}
            >
              Download
            </button>
          ) : (
            <button
              onClick={handleDownload}
              className="w-[55%] text-[20px] max-sm:ml-[70px] lg:ml-36 mt-5 bg-blue-800 text-white py-2 rounded-lg hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-blue-500"
              style={{ fontWeight: 600 }}
            >
              Save
            </button>
          )}
        </div>
      </div>

      <div className="mt-16 lg:grid lg:grid-cols-2 gap-10 px-8 lg:hidden max-sm:py-5">
        <div className="lg:p-8 lg:fixed lg:top-20 lg:right-10  ">
          <h2 className="text-3xl font-bold mb-14 text-center ">Preview</h2>
          <div className="w-[50%] h-[300px] border lg:ml-40 max-sm:ml-20 border-gray-200 bg-white rounded-lg flex justify-center items-center mb-6 ">
            <QrCode
              ref={qrCodeRef}
              size={250}
              link={qrLink}
              color={bgColor}
              qrColor={qrColor}
              styles={style}
              corners={"h"}
              logo={logo}
              logoBg={logoBgColor}
            />
          </div>
          {isSaved ? (
            <button
              onClick={handleDownload}
              className="w-[55%] max-sm:ml-[70px] lg:ml-36 mt-5 bg-blue-800 text-white py-2 rounded-lg hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              Download
            </button>
          ) : (
            <button
              onClick={handleDownload}
              className="w-[55%] max-sm:ml-[70px] lg:ml-36 mt-5 bg-blue-800 text-white py-2 rounded-lg hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              Save
            </button>
          )}
        </div>
        <div className="lg:p-8  ">
          <h2 className="text-3xl font-bold mb-6 max-sm:mt-10">Designing</h2>

          {/* Background Color */}
          <div className="mb-6">
            <label className="block text-lg font-medium mb-2">
              Select your code background color
            </label>
            <div className="flex space-x-2 mb-2">
              {[
                "#FFFFFF",
                "#000000",
                "#FF0000",
                "#00FF00",
                "#34ebd2",
                "#FFFF00",
              ].map((color) => (
                <div
                  key={color}
                  className={`w-14 h-14 border-2 cursor-pointer ${
                    color === bgColor ? "border-black" : "border-gray-100"
                  }`}
                  style={{ backgroundColor: color }}
                  onClick={() => setBgColor(color)}
                />
              ))}
            </div>
            <div className="flex items-center">
              <div className="flex border border-black bg-white w-[58%] text-center items-center">
                <div
                  className="w-12 ml-2 mb-2 border  h-10 rounded-sm mt-2 "
                  style={{ backgroundColor: bgColor }}
                ></div>
                <input
                  type="text"
                  value={bgColor}
                  placeholder="Enter a color value"
                  onChange={(e) => handleColorInputChange(e, setBgColor)}
                  className="w-full text-xl focus:outline-none font-semibold text-gray-400 p-3"
                />
              </div>
            </div>
          </div>
          <hr className="w-[58%] border-gray-300 mb-3" />

          {/* QR Code Color */}
          <div className="mb-6">
            <label className="block text-lg font-medium mb-2">
              Select your QR code color
            </label>
            <div className="flex space-x-2 mb-2">
              {[
                "#FFFFFF",
                "#000000",
                "#FF0000",
                "#00FF00",
                "#34ebd2",
                "#FFFF00",
              ].map((color) => (
                <div
                  key={color}
                  className={`w-14 h-14 border-2 cursor-pointer ${
                    color === qrColor ? "border-black" : "border-gray-100"
                  }`}
                  style={{ backgroundColor: color }}
                  onClick={() => setQrColor(color)}
                />
              ))}
            </div>
            <div className="flex items-center">
              <div className="flex border border-black bg-white w-[58%] text-center items-center">
                <div
                  className="w-12 ml-2 mb-2 border  h-10 rounded-sm mt-2   "
                  style={{ backgroundColor: qrColor }}
                ></div>

                <input
                  type="text"
                  value={qrColor}
                  placeholder="Enter a color value"
                  onChange={(e) => handleQRColorInputChange(e, setQrColor)}
                  className="w-full text-xl focus:outline-none font-semibold text-gray-400 p-3"
                />
              </div>
            </div>
          </div>
          <hr className="w-[58%] border-gray-300 mb-3" />
          {/* QR Code Style */}
          <div className="mb-6">
            <label className="block text-lg font-medium mb-2">
              QR code style
            </label>
            <div className="inline-flex border border-black overflow-hidden">
              <label
                className={`flex items-center px-4 py-2 border-r border-black  cursor-pointer ${
                  style === "squares" ? "bg-blue-50" : "bg-white"
                }`}
                onClick={() => setStyle("squares")}
              >
                <input
                  type="radio"
                  name="style"
                  value="squares"
                  checked={style === "squares"}
                  onChange={(e) => setStyle(e.target.value)}
                  className="hidden"
                />
                <div
                  className={`w-4 h-4 mr-2 border-2 rounded-full flex justify-center items-center ${
                    style === "squares" ? "border-blue-600" : "border-gray-400"
                  }`}
                >
                  {style === "squares" && (
                    <div className="w-2 h-2 bg-blue-800 rounded-full"></div>
                  )}
                </div>
                <span className="text-sm">Squares</span>
              </label>

              <label
                className={`flex items-center px-4 py-2 cursor-pointer ${
                  style === "dots" ? "bg-blue-50" : "bg-white"
                }`}
                onClick={() => setStyle("dots")}
              >
                <input
                  type="radio"
                  name="style"
                  value="dots"
                  checked={style === "dots"}
                  onChange={(e) => setStyle(e.target.value)}
                  className="hidden"
                />
                <div
                  className={`w-4 h-4 mr-2 border-2 rounded-full flex justify-center items-center ${
                    style === "dots" ? "border-blue-600" : "border-gray-400"
                  }`}
                >
                  {style === "dots" && (
                    <div className="w-2 h-2 bg-blue-800 rounded-full"></div>
                  )}
                </div>
                <span className="text-sm">Dots</span>
              </label>
            </div>
          </div>
          <hr className="w-[58%] border-gray-300 mb-3" />
          {/* QR Code Corners */}
          <div className="mb-6">
            <label className="block text-lg font-medium mb-2">
              QR code corners
            </label>
            <div className="inline-flex border border-black  overflow-hidden">
              <label
                className={`flex items-center px-4 py-2 border-r border-black cursor-pointer ${
                  corners === "squares" ? "bg-blue-50" : "bg-white"
                }`}
                onClick={() => setCorners("squares")}
              >
                <input
                  type="radio"
                  name="corners"
                  value="squares"
                  checked={corners === "squares"}
                  onChange={(e) => setCorners(e.target.value)}
                  className="hidden"
                />
                <div
                  className={`w-4 h-4 mr-2 border-2 rounded-full flex justify-center items-center ${
                    corners === "squares"
                      ? "border-blue-600"
                      : "border-gray-400"
                  }`}
                >
                  {corners === "squares" && (
                    <div className="w-2 h-2 bg-blue-800 rounded-full"></div>
                  )}
                </div>
                <span className="text-sm">Squares</span>
              </label>

              <label
                className={`flex items-center px-4 py-2 cursor-pointer ${
                  corners === "round" ? "bg-blue-50" : "bg-white"
                }`}
                onClick={() => setCorners("round")}
              >
                <input
                  type="radio"
                  name="corners"
                  value="round"
                  checked={corners === "round"}
                  onChange={(e) => setCorners(e.target.value)}
                  className="hidden"
                />
                <div
                  className={`w-4 h-4 mr-2 border-2 rounded-full flex justify-center items-center ${
                    corners === "round" ? "border-blue-600" : "border-gray-400"
                  }`}
                >
                  {corners === "round" && (
                    <div className="w-2 h-2 bg-blue-800 rounded-full"></div>
                  )}
                </div>
                <span className="text-sm">Round</span>
              </label>
            </div>
          </div>
          <hr className="w-[58%] border-gray-300 mb-3" />
          {/* Upload Logo */}
          <div className="mb-4">
            <label className="block text-lg font-medium mb-4">
              Add your brand logo in center
            </label>
            <div className="w-14 h-14 border-2 border-gray-500 flex items-center justify-center relative cursor-pointer">
              <input
                type="file"
                accept="image/*"
                onChange={handleLogoUpload}
                className="absolute inset-0 opacity-0 cursor-pointer"
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1"
                stroke="currentColor"
                className="w-8 h-8  "
              >
                <CloudUploadIcon className="text-black" fontSize="large" />
              </svg>
            </div>

            {logo && (
              <div className=" mt-4  ">
                <img src={logo} alt="Logo" className="w-14 h-14 rounded-md " />
                <button
                  onClick={handleDeleteLogo}
                  className="relative -top-16 -right-10  bg-red-600 text-white w-5 h-5 rounded-full flex items-center justify-center"
                  style={{ fontSize: "14px" }}
                >
                  &times;
                </button>
              </div>
            )}
          </div>

          {/* Logo Background Color */}
          <div className="mb-6">
            <label className="block text-lg font-medium mb-2">
              Logo background color
            </label>
            <div className="flex space-x-2 mb-2">
              {[
                "#FFFFFF",
                "#000000",
                "#FF0000",
                "#00FF00",
                "#34ebd2",
                "#FFFF00",
              ].map((color) => (
                <div
                  key={color}
                  className={`w-14 h-14 border-2 cursor-pointer ${
                    color === logoBgColor ? "border-black" : "border-gray-100"
                  }`}
                  style={{ backgroundColor: color }}
                  onClick={() => setLogoBgColor(color)}
                />
              ))}
            </div>
            <div className="flex items-center">
              <div className="flex border border-black bg-white w-[58%] text-center items-center">
                <div
                  className="w-12 ml-2 mb-2 border  h-10 rounded-sm mt-2 "
                  style={{ backgroundColor: logoBgColor }}
                ></div>
                <input
                  type="text"
                  value={logoBgColor}
                  placeholder="Enter a color value"
                  onChange={(e) =>
                    handleLogoBGColorInputChange(e, setLogoBgColor)
                  }
                  className="w-full text-xl focus:outline-none font-semibold  text-gray-400 p-3"
                />
              </div>
            </div>
          </div>
          <hr className="w-[58%] border-gray-300 mb-3" />
        </div>

        {/* Preview Section */}
      </div>
    </div>
  );
}

export default Designing;
